import React, { useEffect } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import BuyFromEth from '../../hooks/dataSenders/buyFromEth';
import GetInvestmentRange from "../../hooks/dataFetchers/getInvestmentRange";
import GetTotalPurchased from "../../hooks/dataFetchers/getTotalPurchased";
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import Allowance from '../../hooks/dataFetchers/allowance';
import ApproveST from '../../hooks/dataSenders/approveST';
import LatestTokenPrice from '../../hooks/dataFetchers/LECTLatestTokenPrice';
import GetBalance from '../../hooks/dataFetchers/getBalance';
import PreSaleEndTime from '../../hooks/dataFetchers/preSaleEndTime';
import axios from 'axios';
import GetFundLimit from '../../hooks/dataFetchers/getFundLimit';
import BigNumber from 'bignumber.js';
import ProgressBar from 'react-bootstrap/ProgressBar';

const Investors = ({ hashId }) => {

    let { account } = useWeb3React();
    let gettingBalance = GetBalance();
    let getEndTime = PreSaleEndTime();
    let getFundEndlimit = GetFundLimit();
    console.log("🚀 ~ file: Investors.js:23 ~ Investors ~ getFundEndlimit:", getFundEndlimit)

    let nowTime = Date.now();
    console.log(nowTime, 'nowTime', parseInt(getEndTime));

    // const num1 = new BigNumber(getFundEndlimit)
    console.log("🚀 ~ file: Investors.js:31 ~ Investors ~ num1:", getFundEndlimit)
    // var BigNumber = require(getFundEndlimit);
    let mBalance = gettingBalance?.toFixed(4);
    console.log("🚀 ~ file: Investors.js:34 ~ Investors ~ mBalance:", mBalance)
    // let asdasd = BigNumber.toNumber()
    // console.log("🚀 ~ file: Investors.js:31 ~ Investors ~ asdasd:", asdasd)


    function LargeNumberFormatter({ number }) {
        let formattedNumber;
        let label;

        if (number >= 1000000) {
            formattedNumber = (number / 1000000).toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
            });
            label = 'M';
        } else if (number >= 1000) {
            formattedNumber = (number / 1000).toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
            });
            label = 'K';
        } else {
            formattedNumber = number.toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
            });
        }

        return (
            <p className="cardprogresshead">
                {formattedNumber} {label}
            </p>
        );
    }




    const [loader, setLoader] = useState(false);
    const [getAmount, setAmount] = useState(null);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [totalUSDT, setTotalUSDT] = useState(null);

    const [check, setCheckAmount] = useState(null);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [error, setError] = useState(null);


    const handleClose4H = () => {
        handleClose2();
        setAmount("");
    }


    const totalUsdtHandle = async () => {
        console.log('start graph......');
        var data = JSON.stringify({
            query: `query MyQuery {
                purchaseds {
                  investment
                }
              }`,
        });
        var config = {
            method: "post",
            url: 'https://api.thegraph.com/subgraphs/name/saifdevblochain/goldenloonie',
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        await axios(config)
            .then(async function (response) {
                console.log("🚀 ~ file: Investors.js:60 ~ response:", response)
                const totalSum = response.data.data.purchaseds.reduce((acc, obj) => acc + parseInt(obj.investment), 0);
                setTotalUSDT(totalSum / 10 ** 6);
            })
            .catch(function (error) {
                console.log("🚀 ~ file: Investors.js:88 ~ error: Investors....", error)
            });
    };

    const [getinvestmentRangeMin, setGetinvestmentRangeMin] = useState(null);
    const [getinvestmentRangeMax, setGetinvestmentRangeMax] = useState(null);
    const investmentRangeCall = GetInvestmentRange();
    console.log(investmentRangeCall, 'min amointttt states');

    const investmentRangeCallHandle = async () => {
        let investmentRes = await investmentRangeCall();
        let aaaaa = investmentRes?.maxCap;
        let aaaaaB = investmentRes?.minCap;

        console.log(aaaaa, aaaaaB, "min amointttt");
        setGetinvestmentRangeMax(aaaaa / 10 ** 18);
        setGetinvestmentRangeMin(aaaaaB / 10 ** 18);
    }

    // Approve =========================================

    const { userApprove } = ApproveST();
    const [approveAmount, setapproveAmount] = useState();
    // console.log("approveAmount:", approveAmount)

    const approveAmountHandle = async () => {
        setLoader(true);
        setAmount("");
        // console.log('checkAllowanceHandle ..............');
        let res = await userApprove(getAmount);
        // console.log(res, 'checkAllowance.....');
        setapproveAmount(res);
        if (res) {
            setLoader(false);
            toast?.success("Token approved")
        }
    }

    // Allowance =========================================

    const { checkAllowance } = Allowance();
    const [allowanceAmount, setAllowanceAmount] = useState();
    console.log("🚀 ~ file: Investors.js:80 ~ Investors ~ allowanceAmount:", allowanceAmount)

    const checkAllowanceHandle = async () => {
        // console.log('checkAllowanceHandle ..............');
        let res = await checkAllowance();
        // console.log(res / 10 ** 18, 'checkAllowance.....', res);
        setAllowanceAmount(res / 10 ** 6);
    }


    function abbrNum(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10, decPlaces);

        // Enumerate number abbreviations
        var abbrev = ["k", "m", "b", "t"];

        // Go through the array backwards, so we do the largest first
        for (var i = abbrev.length - 1; i >= 0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10, (i + 1) * 3);

            // If the number is bigger or equal do the abbreviation
            if (size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number * decPlaces / size) / decPlaces;

                // Handle special case where we round up to the next abbreviation
                if ((number == 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }

        return number;
    }



    // Buy Tokens =========================================

    const { preSaleFromEth } = BuyFromEth();

    const buyTokenHandle = async () => {
        setLoader(true);
        // if (nowTime <= parseInt(getEndTime)) {
        //     toast?.error("Presale time ended")
        // }
        if (!getAmount) {
            handleShow3();
            setError("Please enter amount");
        }
        else if (!account) {
            setError("Please connect your wallet")
            handleShow3();
        }
        else if (allowanceAmount <= 0) {
            // handleClose1();
            approveAmountHandle();
            // console.log("🚀 ~ file: Investors.js:243 ~ buyTokenHandle ~ res: in allowsadfadsfdsafasdsf", res)
            // if (res) {
            //     window?.location?.reload();
            // }
        }
        else if (allowanceAmount <= getAmount) {
            setLoader(false);
            setError(`Your approved token less then ${allowanceAmount}`)
            // toast?.error(`Your approved token less then ${allowanceAmount}`)
            handleShow3();
        }
        else {
            try {
                handleClose1();
                console.log(getAmount, "getAmount");
                let aaa = await preSaleFromEth(getAmount);
                console.log(aaa, 'buy Now contract res', aaa?.code === 4001 ? "yes" : "no");
                if (aaa) {
                    setLoader(false);
                    handleClose();
                    // setAmount("");
                    // toast?.success("Buy Successfully")
                    handleShow2();
                    // setLoader(false);
                }
                setLoader(false);
            } catch (error) {
                console.log('catch okoko okoko buy Now contract res');
                setLoader(false);
                handleShow3();
            }
            setLoader(false);
        }
        setLoader(false);
    }


    let lTokenPrice = LatestTokenPrice();
    // console.log(lTokenPrice, 'lTokenPrice.........');

    const [swapPrice, setSwapPrice] = useState(null);
    console.log(swapPrice, 'swapPrice asddsa.....');
    let value2 = parseFloat(swapPrice).toFixed(4);
    console.log("🚀 ~ file: Investors.js:275 ~ Investors ~ value2:", value2, swapPrice)

    const priceeeeHanlde = async () => {
        if (lTokenPrice) {
            // Now you can use the convertedValue without precision errors
            // console.log('Converted Value: asddsa.....', lTokenPrice);
            // let coinPrice = parseFloat(lTokenPrice);
            // let convrt = lTokenPrice;
            // let asddsa = (getAmount * coinPrice) / (convrt);
            // let adf = asddsa * convrt;
            // console.log('asddsa.....nnnnnnnnnnnnnnnnnnnn', asddsa, getAmount, coinPrice, convrt);
            setSwapPrice(getAmount / lTokenPrice);
            // setSwapPrice1(asddsa / 10 ** 18);
        } else {
            // toast.error('error.....');
        }
    }

    useEffect(() => {
        investmentRangeCallHandle();

        if (account) {
            checkAllowanceHandle();
        }
    }, [account])

    // useEffect(() => {
    //     priceeeeHanlde();
    // }, [getAmount])

    useEffect(() => {
        totalUsdtHandle();
    }, [totalUSDT]);



    useEffect(() => {
        setCheckAmount(getAmount / 10 ** 6);
    }, [getAmount]);




    return (
        <>
            <section className='main_investors' id={hashId}>
                <div className='invester_outerdiv '>
                    <div className='row p-0'>
                        <div className='col-xl-6 col-md-12 ordersm-2'>
                            {/* <div className='outer_imgdivmain'>
                                <div className='left_firstimg'>
                                    <img src='\assets\investors\imagef.svg' alt='img' className='sliderimggs img-fluid' />
                                </div>
                                <div className='onlymobilerespos mx-2'>
                                    <div className='left_firstimgss'>
                                        <img src='\assets\investors\imagesec.svg' alt='img' className='sliderimggs  img-fluid  ' />
                                    </div>
                                    <div className='left_firstimgss'>
                                        <img src='\assets\investors\imageddd.svg' alt='img' className='sliderimggs  img-fluid margin_top_butm ' />
                                    </div>
                                    <div className='left_firstimgss'>
                                        <img src='\assets\investors\imagefor.svg' alt='img' className='sliderimggs  img-fluid ' />
                                    </div>
                                </div>
                            </div> */}
                            <div className="wrapper-img">
                                <img src="\assets\dummy-img.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-xl-6 col-md-12 m-auto ordersm-1'>
                            <div className='main_leftheadingdiv'>
                                <h1 className='Propertiesfsthndg'>
                                    Take your investment to the next level!
                                </h1>
                                <p className='inneerrs_pars'>
                                    Dive into Goldenloonie’s live funds' investment & be a maestro of financial growth.
                                </p>
                                {/* <div className='text_flexdiv'>
                                    <p className='innerparas'>
                                        Funds Collected
                                    </p>
                                    <h6 className='common digits'>
                                        $ {totalUSDT?.toLocaleString()}
                                    </h6>
                                </div> */}
                                <div className='investorsbtns mt-5'>
                                    <button className='login1' onClick={handleShow} >Invest Now</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Modal className='profilemodal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    {
                        loader ?
                            <div class="spinner-border d-flex justify-content-center text-white mx-auto" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            :
                            <div className='wrapperss'>
                                <img src='\assets\Mask group.svg' alt='img' className='img-fluid' />
                                {/* <p style={{ color: 'white', marginTop: '20px' }}>Fund Limit {getFundEndlimit} </p> */}
                                <div className="cardprogress">
                                    <div className="cardprogressheadings">
                                        <p className="cardprogresspara">Funds Remaining Limit</p>
                                        <h6 className="cardprogresshead">{(getFundEndlimit)?.toFixed(4)}</h6>
                                        {/* {getFundEndlimit} */}
                                    </div>
                                    <ProgressBar now={60} />

                                </div>
                                <div className='inermodaldiv'>
                                    <h6 className='Iccnvestment'>
                                        Investment Amount
                                    </h6>
                                    <div className='lastinneerr'>
                                        <p className='balan'>
                                            Balance:&nbsp;
                                            <span>
                                                {mBalance} USDT
                                            </span>
                                            <input placeholder='00000' type='number' value={getAmount} onChange={(e) => setAmount(e.target.value)} className='get-amount-input' />
                                        </p>
                                    </div>
                                </div>
                            </div>
                    }
                    {
                        !getAmount === null || getAmount > 0 && getAmount < 19 && mBalance > 19 ?
                            <p className='text-denger text-center m-0' style={{ color: 'red', marginTop: '0px' }}>Please Enter more then 20 USDT</p>
                            : null
                    }
                    <div className='endbtns'>
                        {
                            getAmount < 19 || loader ?
                                <button className='login opacity-25' style={{ cursor: 'not-allowed' }} disabled>Invest</button>
                                :
                                <>
                                    {
                                        account ?
                                            <button className='login' onClick={handleShow1}>Invest</button>
                                            :
                                            <button className='login opacity-25' style={{ cursor: 'not-allowed' }} disabled>Invest</button>
                                    }
                                </>
                        }
                    </div>
                </Modal.Body>

            </Modal>


            <Modal className='profilemodal' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='parentinputstt'>
                        <p className='surree'>
                            Are you sure you want to buy {getAmount / lTokenPrice} GLN for {getAmount} USDT?
                        </p>
                    </div>
                    <div className='digitbtnss'>
                        <button className='canccell' onClick={handleClose1}>Cancel</button>
                        <button className='loginsssaa' onClick={buyTokenHandle}>Yes i’m sure</button>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal className='profilemodal' show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\successtick.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>You have successfully purchased <span>{getAmount / lTokenPrice} GLN </span>for <span>{getAmount} USDT</span></p>
                        </div>
                        <div className='okaybtn' onClick={handleClose4H}>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='profilemodal' show={show3} onHide={handleClose3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\cross.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>Purchased Failed..</p>
                        </div>
                        <div className='okaybtn'>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/* <Modal className='profilemodal' show={show4} onHide={handleClose4} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\successtick.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>You have successfully purchased <span>{value2} GLN </span>for <span>{getAmount} USDT</span></p>
                        </div>
                        <div className='okaybtn' onClick={handleClose4H}>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
            {/* su */}
        </>
    )
}

export default Investors
