import React, { useEffect, useState, useRef } from "react";
import "./login.scss";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Modal } from "react-bootstrap";



const Loginone = () => {



  return (
    <>

      <section className="main-login">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-sm-12">
              <div className="maincard">
                <div className="logincard">
                  <div className="innerlogo">
                    <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                  </div>

                  <div className="cardtext">
                    <h6>Enter your 2fa code</h6>
                    <p>
                      Enter 6-digit code from your two factor authentication APP
                    </p>
                  </div>
                  <div className="parentinputs">

                    <input

                      className="code"
                      type="text"
                      placeholder="4"

                    />
                    <input

                      className="code"
                      type="text"
                      placeholder="2"

                    />
                    <input

                      className="code"
                      type="text"
                      placeholder="0"

                    />
                    <input

                      className="code"
                      type="text"
                      placeholder="3"

                    />
                    <input

                      className="code"
                      type="text"
                      placeholder="_"

                    />
                    <input

                      className="code"
                      type="text"
                      placeholder=""

                    />


                  </div>

                  <Link to="/landing">
                    <div className="digitbtns">
                      <button>2 digits left</button>
                    </div>
                  </Link>
                  <div className="last-text">
                    <p className="creat-actot">
                      Don’t have an account? <span>Create Account</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Recover 2FA Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="recover2fa">
            <img src="\Assets\2farecover.svg" alt="img" className="img-fluid" />
            <h6>Email sent successfully for 2FA Code Recovery</h6>
          </div>
        </Modal.Body>
      </Modal> */}
    </>

  );
};

export default Loginone;
