import React, { useEffect, useState } from "react";
import "./login.scss";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import { Modal } from "react-bootstrap";

const Signup = ({ setToken }) => {

    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    console.log("🚀 ~ file: Signup.js:17 ~ Signup ~ password:", password, confirmPassword)

    let localToken = localStorage?.getItem('accessToken');
    const [error, setError] = useState(null);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [showP, setShowP] = useState(false);
    const [showP1, setShowP1] = useState(false);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
            setError('Enter valid email');
        } else {
            setError(null);
        }

        setEmail(event.target.value);
    };



    // const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        const hasCapitalLetter = /[A-Z]/.test(newPassword);
        const hasNumber = /\d/.test(newPassword);
        const hasSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword);
        const isLengthValid = newPassword.length >= 8;
        const isValid = hasCapitalLetter && hasNumber && hasSymbol && isLengthValid;

        setPassword(newPassword);
        setIsPasswordValid(isValid);
    };




    const payload = {
        name: name,
        email: email,
        password: password
    }

    const signupHandle = async () => {

        if (!password && !confirmPassword && !confirmPassword) {
            setError("Please fill all the fields")
            handleShow3();
            return
        }

        if (!password) {
            setError("Please enter your paasword")
            handleShow3();
            // return
        }

        if (!confirmPassword) {
            setError("Please enter your confirm paasword")
            handleShow3();
            // return
        }

        if (!password && !confirmPassword) {
            setError("Please enter your paasword and confirm password");
            handleShow3();
            // return
        }

        else if (password !== confirmPassword) {
            setError("Paasword must be same")
            handleShow3();
            // return
        }

        else if (password?.length < 8) {
            setError("Paasword must more then 8 letters");
            handleShow3();
            // return
        }

        else if (error) {
            setError(error);
            handleShow3();
            return
        }

        if (password && confirmPassword) {
            setLoader(true);
            await axios.post(`${API_URL}/v1/auth/register`, payload)
                .then((res) => {
                    console.log(res, 'res in signup');
                    // if (localToken) {
                    //     localStorage?.setItem('accessToken', res?.data?.token?.accessToken);
                    // } 
                    // else if (!localToken) {
                    //     localStorage?.setItem('accessToken', res?.data?.token?.accessToken);
                    // }
                    localStorage?.setItem('accessToken', res?.data?.token?.accessToken);
                    // toast?.success("sign up successfully");
                    handleShow2();
                    setLoader(false);
                    // history.push('/login');
                })
                .catch((error) => {
                    setLoader(false);
                    console.log("XOXOXOXOXOXO", error?.response?.data?.message);
                    handleShow3();
                    setError(error?.response?.data?.message);
                    // if (error) {

                    // }
                })
        }
    }

    return (
        <>
            {loader && <Loader />}
            <section className="main-login">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-10 col-sm-12 m-auto">
                            <div className="maincard">
                                <div className="logincard">
                                    <div className="innerlogo">
                                        <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                                    </div>
                                    <div className="cardtext">
                                        <h6 className="my-5">sign up</h6>
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e) => setName(e?.target?.value)}
                                        />
                                        <div
                                            // style={{ position: "unset" }}
                                            className="text-danger error-margin"
                                        >
                                            {/* <small>cscsc</small> */}
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            value={email}
                                            onChange={handleChange}
                                        />
                                        <div
                                            // style={{ position: "unset" }}
                                            className="text-danger error-margin"
                                        >
                                            {/* <small>cscsc</small> */}
                                        </div>
                                        <div className="parent">
                                            <input
                                                placeholder="Password"
                                                type={showP ? "text" : "password"}
                                                value={password}
                                                // onChange={(e) => setPassword(e?.target?.value)}
                                                onChange={handlePasswordChange}
                                            />
                                            <img
                                                src="\assets\login\eye.svg"
                                                className="img-fluid eye-btn"
                                                alt="img"
                                                onClick={() => setShowP(!showP)}
                                            />
                                        </div>
                                        {!isPasswordValid && (
                                            <p className="error-message">
                                                Password must contain a capital letter, a number, a symbol, and be at least 8 characters long.
                                            </p>
                                        )}
                                        <div
                                            // style={{ position: "unset" }}
                                            className="text-danger error-margin"
                                        >
                                            {/* <small>hghdfhdfhdfh</small> */}
                                        </div>
                                        <div className="parent">
                                            <input
                                                placeholder="Confirm Password"
                                                type={showP1 ? "text" : "password"}
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e?.target?.value)}
                                            />
                                            <img
                                                src="\assets\login\eye.svg"
                                                className="img-fluid eye-btn"
                                                alt="img"
                                                onClick={() => setShowP1(!showP1)}
                                            />
                                        </div>
                                        {/* {
                                            <p color="danger-text">Password must be same</p>
                                        } */}
                                    </div>
                                    {/* <Link to="/loginone"> */}
                                    <div className="endbtn">
                                        <button onClick={signupHandle}>Sign Up</button>
                                    </div>
                                    {/* </Link> */}
                                    <div className="last-text">
                                        <p className="creat-actot">
                                            Already have an account?
                                            <span>
                                                <Link to={'/login'}>
                                                    Log In
                                                </Link>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Modal className='profilemodal' show={show2} onHide={handleClose2} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\successtick.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>Sign up successfully</p>
                        </div>
                        <div className='okaybtn' onClick={() => history.push('/login')}>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='profilemodal' show={show3} onHide={handleClose3} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\cross.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>{error}</p>
                        </div>
                        <div className='okaybtn' onClick={handleClose3}>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Signup
