import React, { useCallback, useEffect } from 'react'
import { preSaleContractHelper } from '../../utils/contractHelpers';
import useWeb3 from '../useWeb3';
import Environment from '../../utils/Environment';
import { useWeb3React } from '@web3-react/core';

const GetInvestmentRange = () => {
  let { account } = useWeb3React();
  const web3 = useWeb3();

  const contractAddress = Environment?.preSaleContract;
  const contract = preSaleContractHelper(contractAddress, web3);

  // const investmentRangeCall = useCallback(
  //   async () => {
  //     try {
  //       console.log('/ssssssssssss investmentRes', contract.methods, contract.methods?.investmentRange);
  //       var response = await contract.methods.investmentRange()?.call({ from: account })
  //       console.log(response, 'response, investmentRes');
  //     } catch (error) {
  //       console.log('catch error', error);
  //     }
  //     return response
  //   },
  //   [contract]
  // );

  useEffect(() => {
    const investmentRangeCall = async () => {
      try {
        console.log('/ssssssssssss investmentRes', contract.methods, contract.methods?.investmentRange);
        var response = await contract.methods.investmentRange()?.call({ from: account })
        console.log(response, 'response, investmentRes');
        return response;
      } catch (error) {
        console.log('catch error', error);
      }
    }
    investmentRangeCall();
  }, []);
  // return { investmentRangeCall: investmentRangeCall };
}

export default GetInvestmentRange