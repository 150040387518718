import React from 'react'
import Navbar from '../landing/header/Navbar'
import "./propertydetail.scss";
import { Link } from 'react-router-dom';
import Faqs from '../Faqs/Faqs';
import Aboutst from './Aboutst';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Slider from './Slider';
import Investors from '../Currentproperty/Investors';


const Propertydetail = () => {

    return (

        <>
            <Navbar />
            <section className="main-Propertydetail">
                <div className="custom-container ">
                    <div className="backtopbtns ">
                        <Link to="/" className="back-btn">
                            <img src="\assets\login\arrow-left.svg" alt="img" className="img-fluid" />  Back
                        </Link>
                    </div>
                    <Investors/>

                    {/* <div className='row'>
                        <div className='col-xl-6 col-md-12  m-auto'>
                    
                            <Slider/>
                        </div>
                        <div className='col-xl-6 col-md-12 m-auto'>
                            <div className='inner-right'>
                                <h6 className='fst-hedng'>
                                    Home  3406 Parkview Drive Rapid City, SD 57701
                                </h6>
                                <h4 className='sed-hedng'>
                                    3406 Parkview Drive Rapid City, SD 57701
                                </h4>
                                <p className='fst-para'>
                                    This 4-bed 2-bath property offers plenty of space with a large family room, and a backyard perfect for entertaining. The central location to schools, grocery stores, and nearby parks make it the perfect home.
                                </p>
                                <p className='sec-para'>
                                    With a little effort, this home can be elevated from its dated and neglected interior into a modern home while still maintaining its old charm.
                                </p>
                                <div className='total-investment'>
                                    <h5 className='investment'>
                                        Total Investment
                                    </h5>
                                    <h5 className='investment-amount'>
                                        $136,800
                                    </h5>
                                </div>
                                <div className='total-investment'>
                                    <h5 className='investment'>
                                        Cash on Cash Return
                                    </h5>
                                    <h5 className='investment-amount'>
                                        7.67%
                                    </h5>
                                </div>
                                <Link to="/dashboardpanel">
                                    <div className="endbtn">
                                        <button >View on Dashboard</button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-xl-4 col-md-12 m-auto'>
                            <div className='sec-summary-section'>
                                <h4 className='ssummary'>
                                    Investment Summary
                                </h4>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Property
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $120,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Renovation Fund
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $0
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Platform Fee
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $6,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Legal and Administrative
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $4,800
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Maintenance Reserve
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $6,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Net Annual Cash Flow
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $10,499
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Est. 1-Year Appreciation
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $3,600
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Est. 1 Year Return
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        10.3%
                                    </h4>
                                </div>

                            </div>

                        </div>
                        <div className='col-xl-4 col-md-12 m-auto'>
                            <div className='sec-summary-section'>
                                <h4 className='ssummary'>
                                    Investment Summary
                                </h4>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Property
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $120,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Renovation Fund
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $0
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Platform Fee
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $6,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Legal and Administrative
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $4,800
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Maintenance Reserve
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $6,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Net Annual Cash Flow
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $10,499
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Est. 1-Year Appreciation
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $3,600
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Est. 1 Year Return
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        10.3%
                                    </h4>
                                </div>

                            </div>

                        </div>
                        <div className='col-xl-4 col-md-12 m-auto'>
                            <div className='sec-summary-section'>
                                <h4 className='ssummary'>
                                    Investment Summary
                                </h4>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Property
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $120,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Renovation Fund
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $0
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Platform Fee
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $6,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Legal and Administrative
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $4,800
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Maintenance Reserve
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $6,000
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Net Annual Cash Flow
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $10,499
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Est. 1-Year Appreciation
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        $3,600
                                    </h4>
                                </div>
                                <hr></hr>
                                <div className='iner-flexdiv'>
                                    <h4 className='sumry-property'>
                                        Est. 1 Year Return
                                    </h4>
                                    <h4 className='sumry-propertydig'>
                                        10.3%
                                    </h4>
                                </div>

                            </div>

                        </div>

                    </div> */}

                    {/* FAQS section */}
                    {/* <div className='row'>
                        <div className='col-11 m-auto '>
                            <Faqs />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 m-auto '>
                            <Aboutst />

                        </div>
                    </div> */}

                </div>
            </section>
        </>
    )
}

export default Propertydetail
