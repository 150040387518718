import React from 'react'
import "./enter2fa.scss"
import { Link } from 'react-router-dom'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const Enter2fa = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    return (
        <>
            <section className='entercode'>
                <div className="custom-container">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='backbutton'>
                                <Link to="/"><img src='\assets\arrow-left.svg' alt='img' className='img-fluid' />Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-7 m-auto'>
                            <div className='enterode'>
                                <div className='maincard'>
                                    <div className='resethead'>
                                        <h2>Enter your 2fa code</h2>
                                        <p>Enter 6-digit code from your two factor authentication APP.</p>
                                    </div>
                                    <div className='parentinputs'>
                                        <input className='code' type='text' placeholder='_' />
                                        <input className='code' type='text' placeholder='_' />
                                        <input className='code' type='text' placeholder='_' />
                                        <input className='code' type='text' placeholder='_' />
                                        <input className='code' type='text' placeholder='_' />
                                    </div>
                                    <div className='leftbtn'>
                                        <button>2 digits left</button>
                                        <p onClick={handleShow}>Lost your 2FA Code? <span>Disable 2FA Code</span></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal className='googlemodal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enable Google Authenticator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mainheading'>
                        <h2>Scan this QR code in the Authenticator app</h2>
                    </div>
                    <div className='qrimg'>
                        <img src='\assets\qr.svg' alt='img' className='qrcode' />
                    </div>
                    <div className='innerpara'>
                        <p>- Or -</p>
                        <h6>EWP5RTKQWK2Y2OIM</h6>
                        <p className='grey'>If you are unable to scan the QR code, please enter this code manually into the app.</p>
                    </div>
                    <div className='next'>
                        <button onClick={() => {
                            handleShow1();
                            handleClose();
                        }
                        }>Next</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='googlemodal' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enable Google Authenticator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mainheading'>
                        <h2>Save this Backup Key in a secure location</h2>
                    </div>
                    <div className='file'>
                        <img src='\assets\file-access.svg' alt='img' className='fileinner' />
                    </div>
                    <div className='innerpara'>
                        <h6>EWP5RTKQWK2Y2OIM</h6>
                        <p className='grey'>If you are unable to scan the QR code, please enter this code manually into the app.</p>
                    </div>
                    <div className='next'>
                        <button className='backbtn'>Back</button>
                        <button className='innerbtn' onClick={() => {
                            handleShow2();
                            handleClose1();
                        }
                        }>Next</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='googlemodal' show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enable Google Authenticator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mainheading'>
                        <h2>Enable Authenticator by verifying your account</h2>
                    </div>
                    <div className='googleinput'>
                        <h6>Authenticator Code</h6>
                        <input type='text' placeholder='Enter code' />
                    </div>

                    <div className='next'>
                        <button className='backbtn'>Back</button>
                        <button className='innerbtn' onClick={() => {
                            handleShow3();
                            handleClose2();
                        }
                        }
                        >Next</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='googlemodal' show={show3} onHide={handleClose3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enable Google Authenticator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='successfully'>
                        <div className='mainimagesss'>
                            <img src='\assets\successfullydone.svg' alt='img' className='done' />
                        </div>
                        <div className='innertextsss'>
                            <h6>Authenticator Enabled</h6>
                            <p>You have successfully enabled Authenticator to protect your account.</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Enter2fa