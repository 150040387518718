import React from 'react'
import "./work.scss"
const Work = () => {
    return (
        <>
            <section id='work' className='mainwork'>
                <div className="custom-container">
                    <div className='mainheading'>
                        <h2>How does it work?</h2>
                    </div>
              
                <div className='parentwork'>
                    <div className='maincard'>
                        <div className='upperimages'>
                            <div className='right'>
                                <img src='\assets\search.svg'alt='img' className='search'/>
                            </div>
                            <div className='left'>
                                <img src='\assets\one.svg' alt='img' className='one'/>
                            </div>
                        </div>
                        <div className='headings'>
                            <h2>Access Your Dashboard </h2>
                            <p>Begin by logging into your dashboard, which gives you a comprehensive view of all available features and tools. </p>
                        </div>
                    </div>
                    <div className='maincard'>
                        <div className='upperimages'>
                            <div className='right'>
                                <img src='\assets\dashbord.svg'alt='img' className='search'/>
                            </div>
                            <div className='left'>
                                <img src='\assets\two.svg' alt='img' className='one'/>
                            </div>
                        </div>
                        <div className='headings'>
                            <h2>Invest with Ease  </h2>
                            <p>Input the amount you wish to invest in and hit the "Confirm" button. </p>
                        </div>
                    </div>
                    <div className='maincard'>
                        <div className='upperimages'>
                            <div className='right'>
                                <img src='\assets\dollar.svg'alt='img' className='search'/>
                            </div>
                            <div className='left'>
                                <img src='\assets\three.svg' alt='img' className='one'/>
                            </div>
                        </div>
                        <div className='headings'>
                            <h2>Become A Shareholder  </h2>
                            <p>Upon successful transaction completion, you'll acquire a proportionate share of the properties. Congratulations on your new investment!  </p>
                        </div>
                    </div>
                    <div className='maincard'>
                        <div className='upperimages'>
                            <div className='right'>
                                <img src='\assets\desire.svg'alt='img' className='search'/>
                            </div>
                            <div className='left'>
                                <img src='\assets\four.svg' alt='img' className='one'/>
                            </div>
                        </div>
                        <div className='headings'>
                            <h2>Invest in GoldenLoonie Token </h2>
                            <p>Elevate your portfolio by investing in our token. Via your dashboard, simply click "Buy Tokens" to get started. </p>
                        </div>
                    </div>
                    <div className='maincard'>
                        <div className='upperimages'>
                            <div className='right'>
                                <img src='\assets\investementfive.svg'alt='img' className='search'/>
                            </div>
                            <div className='left'>
                                <img src='\assets\five.svg' alt='img' className='one'/>
                            </div>
                        </div>
                        <div className='headings'>
                            <h2>Streamlined Transactions </h2>
                            <p>Enjoy the convenience and transparency of our platform, bolstered by self-executing smart contracts that automate and verify each transaction.</p>
                        </div>
                    </div>
                    <div className='maincard'>
                        <div className='upperimages'>
                            <div className='right'>
                                <img src='\assets\flag.svg'alt='img' className='search'/>
                            </div>
                            <div className='left'>
                                <img src='\assets\six.svg' alt='img' className='one'/>
                            </div>
                        </div>
                        <div className='headings'>
                            <h2>Effortless Transactions</h2>
                            <p>With our user-friendly dashboard, investing in real estate and tokens has never been this effortless. Dive in today! </p>
                        </div>
                    </div>
                </div>
                </div>
            </section>

        </>
    )
}

export default Work