import { useCallback, useEffect, useState } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { preSaleTokenContractHelper } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import useRefresh from "./useRefresh";

const GetBalance = () => {
    const [balance, setBalance] = useState(0);
    const web3 = useWeb3();
    let { account } = useWeb3React();
    console.log("🚀 ~ file: getBalance.js:48 ~ GetBalance ~ account:", balance, account);

    const { fastRefresh } = useRefresh();
    const contractAddress = Environment?.preSaleToken;
    const contract = preSaleTokenContractHelper(contractAddress, web3);

    useEffect(() => {
        const fetchBalance = async () => {
            var response = await contract.methods.balanceOf(account)?.call()
            console.log('repnse:::: in balanceOf', response)
            setBalance(response / 10 ** 6)
        }
        if (account) {
            console.log("🚀 ~ file: getBalance.js:61 ~ useEffect ~ account:", account)
            fetchBalance();
        }
    }, [contract, fastRefresh]);

    return balance;
}

export default GetBalance