import { useWeb3React } from '@web3-react/core';
import React from 'react'
import useWeb3 from '../useWeb3';
import Environment from '../../utils/Environment';
import { preSaleContractHelper } from '../../utils/contractHelpers';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

const BuyFromEth = () => {
    const chainId = window.localStorage.getItem("chain")
    console.log(chainId, 'chainId');
    let { account } = useWeb3React();
    const web3 = useWeb3();

    const contractAddress = Environment?.preSaleContract;
    console.log("🚀 ~ file: buyFromEth.js:16 ~ BuyFromEth ~ contractAddress:", contractAddress)
    // const contractAddressBSC = Environment?.PreSaleContractBSC;

    const contract = preSaleContractHelper(contractAddress, web3);
    // const contractBSC = getPreSalePriceContractHelperBSC(contractAddressBSC, web3);

    const preSaleFromEth = useCallback(
        async (getAmount) => {
            console.log(getAmount, 'amount...............');

            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = parseInt(gasPrice) + 3000000000;
            console.log(gasPrice, 'gasPrice');
            // var gasFunPrice;
            // web3.eth.getGasPrice().then((result) => {
            //     var result2 = parseInt(result) + 3000000000
            //     // // console.log("gasfun", typeof result2, result2)
            //     gasFunPrice = result2.toString()
            // })
            // let towayValue = web3.utils.toMwei(getAmount.toString(), "ether");

            // console.log(getAmount, 'amount...............', towayValue);

            // const getAmount = 0.123456; // Replace this with your Ether amount

            // Convert Ether to Mwei with 6 decimal places
            const toWei = web3.utils.toWei(getAmount.toString(), 'ether'); // Convert to Wei
            const toMwei = web3.utils.fromWei(toWei, 'mwei'); // Convert Wei to Mwei
            
            // Convert to Mwei with 6 decimal places
            const mweiWithSixDecimals = parseFloat(toMwei).toFixed(6);
            
            console.log(mweiWithSixDecimals, '6 decimal places');

            const sixDecimal = getAmount * 10 ** 6;
            console.log("🚀 ~ file: buyFromEth.js:51 ~ sixDecimal:", sixDecimal)

            try {
                // if (account) {
                    // console.log('/ssssssssssss chainsss BSC', contract?.methods?.buyTokens);
                    // var response = await contract.methods.buyTokens(towayValue)?.send({
                    //     // value: towayValue,
                    //     from: account,
                    // })
                    // const gas = await contract.methods.buyTokens(towayValue).estimateGas({ from: account });
                    // console.log(gas, gasPrice, 'towayValue');
                    // const details = await contract?.methods?.buyTokens(towayValue).send({
                    //     gas: gas, gasPrice: gasPrice, from: account
                    // })
                    // return details;
                    // try {
                    //     const gas = await contract.methods.buyTokens(towayValue).estimateGas({ from: account });
                    //     const details = await contract?.methods?.buyTokens().send({
                    //         gas: gas, gasPrice: gasPrice, value: towayValue, from: account
                    //     })
                    //     return details;
                    // } catch (error) {

                    // }
                // }
                 if (account) {
                    console.log('/ssssssssssss chainsss', contract.methods, account);
                    // var response = await contract.methods.buyTokens("10000000")?.send({
                    //     // value: towayValue,
                    //     from: account
                    // })

                    // const gas = await contract.methods.buyTokens('10000000').estimateGas({ from: account });
                    const details = await contract?.methods?.buyTokens(sixDecimal).send({
                        from: account
                    })
                    return details;
                }
            } catch (error) {
                console.log('catch error', error);
                toast?.error(error?.message);
            }
            // return response
        },
        [contract]
    );
    return { preSaleFromEth: preSaleFromEth };
}

export default BuyFromEth