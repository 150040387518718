// import logo from './logo.svg';
import '../../App.scss';
import React, { useState, useEffect } from "react";
import Navbar from './header/Navbar';
import Banner from './Mainbanner/Banner';
import Buytoken from '../Buytoken/Buytoken';
import Work from '../Work/Work';
import Faqs from '../Faqs/Faqs';
import Lastsection from '../Lastsection/Lastsection';
import Currentproperty from '../Currentproperty/Currentproperty';
import Footer from '../Footer/Footer';



function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // const [userData, setUserData] = useState(null);
  // console.log("🚀 ~ file: Landing.js:23 ~ Landing ~ userData:", userData)

  // const { account } = useWeb3React();

  // let localUserId = localStorage.getItem('userId');


  // const getUserHandle = async () => {
  //   try {
  //     const response = await axios.post(`${API_URL}/v1/users/getUser`, { _id: localUserId });

  //     if (response) {
  //       // Handle success
  //       setUserData(response.data);
  //       console.log('Request successful', response.data);
  //     } else {
  //       // Handle error
  //       console.error('Request failed');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };



  // useEffect(() => {
  //   if (localUserId) {
  //     // createUserHandle();
  //     getUserHandle();
  //   } else {
  //   }
  // }, [account])


  return (
    <>
      <Navbar />
      <Banner />
      <Currentproperty hashId={'with-hash-fragment'} />
      <Buytoken hashId={'with-hash-fragment'} />
      <Work />
      <Faqs />
      <Lastsection />
      <Footer />
    </>
  );
}

export default Landing;