import { useWeb3React } from '@web3-react/core';
import React from 'react'
import useWeb3 from '../useWeb3';
import Environment from '../../utils/Environment';
import { preSaleContractHelper } from '../../utils/contractHelpers';
import { useCallback } from 'react';
import { useEffect } from 'react';
import useRefresh from './useRefresh';
import { useState } from 'react';

const PreSaleEndTime = () => {
    const [endTime, setEndTime] = useState(0);
    const web3 = useWeb3();
    let { account } = useWeb3React();
    console.log("🚀 ~ file: getBalance.js:48 ~ GetBalance ~ account:", account);

    const { fastRefresh } = useRefresh();


    // useEffect(() => {
    //     const fetchBalance = async () => {
    //         var response = await contract.methods.endTime()?.call()
    //         console.log('repnse:::: in endTime', response)
    //         setEndTime(response)
    //     }

    //     fetchBalance();
    // }, [contract, fastRefresh]);

    // return endTime;

    const preSaleETime = useCallback(async () => {
        try {
            const contractAddress = Environment?.preSaleContract;
            const contract = preSaleContractHelper(contractAddress, web3);

            console.log('innnnnnnnnnnnnn');
            let response = await contract.methods.endTime()?.call()
            console.log('repnse:::: in endTime innnnnnnnnnnnnn', response)
            return response
        } catch (error) {
            console.log('innnnnnnnnnnnnn', error);
        }
        // setEndTime(response)
    }, [web3]);
    return { preSaleETime: preSaleETime }
}

export default PreSaleEndTime