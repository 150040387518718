import React from 'react'
import "./lastsection.scss"
import { Link } from 'react-router-dom'
const Lastsection = () => {
    return (
        <>
            <div className="custom-container">
                <section id='Lastsection' className='lastsection'>
                    <div className='row'>
                        <div className='col-12 m-auto p-0'>
                            <div className='headingparentss'>
                                <h2>READY TO ELEVATE YOUR REAL ESTATE INVESTMENTS? </h2>
                                <p>Step into the future with GoldenLoonie. Our intuitive real estate token platform unlocks unparalleled opportunities. Dive in today and turn aspirations into concrete financial milestones. </p>
                                <Link to="/golden">
                                    <div className='logins'>
                                        <button className='login1'>Learn more</button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Lastsection