import React, { useEffect, useState } from "react";
import "./login.scss";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import { toast } from "react-toastify";

const Forgetpassword = ({ setToken }) => {

    const history = useHistory();

    const [fPassword, setFPassword] = useState(null);
    const [fPasswordAPiRes, setFPasswordAPiRes] = useState(false);

    const payload = {
        email: fPassword,
    }

    const forgetPassHandle = () => {
        axios.post(`${API_URL}/v1/auth/send-password-reset`, payload)
            .then(response => {
                console.log(response.data, 'create user..........');
                // setResponseData(response.data);
                // if (response.data) {
                //     localStorage.setItem('userId', response?.data?.user?.id)
                // }
                // if (response.data.msg === "Wallet address must be unique.") {
                //     toast.error(response.data.msg);
                // }
                // else {
                //     toast.success(response.data.msg);
                // }
                // history.push('/loginone');

                setFPasswordAPiRes(true);
            })
            .catch((error) => {
                console.error('Error:::::::::::::::::::>>>>>>>>>>>>>>>>', error);
                toast.error(error);
            });
    };

    return (
        <>

            <section className="main-login">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-10 col-sm-12 m-auto">
                            <div className="maincard">
                                <div className="logincard">
                                    <div className="innerlogo">
                                        <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                                    </div>
                                    <div className="innerlogo">
                                        <img src="\assets\login\My password-cuate 1.svg" alt="img" className="img-fluid" />
                                    </div>
                                    {
                                        fPasswordAPiRes ?
                                            <div className="cardtext">
                                                <h6>Please check email</h6>
                                            </div>
                                            :
                                            <>
                                                <div className="cardtext">
                                                    <h6>Forgot your password?</h6>
                                                    <p>
                                                        Enter your registered email to receive password reset <br></br>instructions.
                                                    </p>
                                                    <input
                                                        type="text"
                                                        placeholder="Username or Email"
                                                        value={fPassword}
                                                        onChange={(e) => setFPassword(e?.target?.value)}
                                                    />
                                                </div>
                                                {/* <Link to="/loginone"> */}
                                                <div className="endbtn">
                                                    <button onClick={forgetPassHandle}>send</button>
                                                </div>
                                            </>
                                    }
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Forgetpassword
