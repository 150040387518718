import React, { useEffect, useState } from "react";
import "./login.scss";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import { useHistory } from 'react-router-dom';
import Loader from "../loader/Loader";
import { Modal } from "react-bootstrap";

const Login = ({ setToken }) => {

    const [loader, setLoader] = useState(false);
    console.log("🚀 ~ file: Login.js:13 ~ Login ~ loader:", loader)
    const history = useHistory();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [showP, setShowP] = useState(false);

    let localToken = localStorage?.getItem('accessToken');

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [error, setError] = useState(null);

    const payload = {
        email: email,
        password: password
    }

    const signinHandle = async () => {

        // if (password !== confirmPassword) {
        //     toast?.error("Paasword must be same");
        //     return
        // } 
        // else 
        if (password?.length < 6) {
            // toast?.error("Paasword must more then 6 letters");
            setLoader(false);
            setError("Paasword must more then 6 letters")
            handleShow3();
            return
        }
        setLoader(true);

        await axios.post(`${API_URL}/v1/auth/login`, payload)
            .then((res) => {
                console.log(res, 'res in signup');
                // if (localToken) {
                // }

                // if (res?.code === 401) {
                //     console.log(err, 'error in login resssss');
                //     toast?.error("Incorrect email or password");
                // }

                localStorage?.setItem('userId', res?.data?.user?.id);
                localStorage?.setItem('accessToken', res?.data?.token?.accessToken);
                setLoader(false);
                // toast?.success("Login successfully");
                handleShow2();
                setEmail("");
                setPassword("");
                // history.push('/');
            })
            .catch((err) => {
                // toast?.error("Incorrect email or password");
                setLoader(false);
                setError("Incorrect email or password")
                handleShow3();
                // if (err?.code === 401) {
                //     console.log(err, 'error in login');
                // }
            })
    }


    return (
        <>
            {loader && <Loader />}

            <section className="main-login">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-10 col-sm-12 m-auto">
                            <div className="maincard">
                                <div className="logincard">
                                    <div className="innerlogo">
                                        <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                                    </div>
                                    <div className="cardtext">
                                        <h6>log in</h6>
                                        <p>
                                            Please enter your credentials below to log into your account.
                                        </p>
                                        <input
                                            type="text"
                                            placeholder="Username or Email"
                                            value={email}
                                            onChange={(e) => setEmail(e?.target?.value)}
                                        />

                                        <div
                                            // style={{ position: "unset" }}
                                            className="text-danger error-margin"
                                        >
                                            {/* <small>cscsc</small> */}
                                        </div>
                                        <div className="parent">
                                            <input
                                                placeholder="Passwords"
                                                type={showP ? "text" : "password"}
                                                value={password}
                                                onChange={(e) => setPassword(e?.target?.value)}
                                            />
                                            <div
                                                style={{ position: "unset" }}
                                                className="text-danger error-margin"
                                            >
                                                {/* <small>fcbxxfgsfg</small> */}
                                            </div>
                                            <img
                                                src="\assets\login\eye.svg"
                                                className="img-fluid eye-btn"
                                                alt="img"
                                                onClick={() => setShowP(!showP)}
                                            />
                                        </div>
                                        <div
                                            // style={{ position: "unset" }}
                                            className="text-danger error-margin"
                                        >
                                            {/* <small>hghdfhdfhdfh</small> */}
                                        </div>
                                    </div>
                                    <div className="twice-div">
                                        <div className="chec">
                                            <label class="material-checkbox">
                                                <input type="checkbox" />
                                                <span class="checkmark"></span>
                                                Keep me logged in
                                            </label>
                                        </div>
                                        <Link to="/Forgetpassword">Forgot Password?</Link>
                                    </div>
                                    {/* <Link to="/loginone"> */}
                                    <div className="endbtn">
                                        <button onClick={signinHandle}>Log In</button>
                                    </div>
                                    {/* </Link> */}
                                    <div className="last-text">
                                        <p className="creat-actot">
                                            Don’t have an account?
                                            <span>
                                                <Link to={'/signup'}>
                                                    Create Account
                                                </Link>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Modal className='profilemodal' show={show2} onHide={handleClose2} centered backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\successtick.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>Login successfully</p>
                        </div>
                        <div className='okaybtn' onClick={() => history.push('/')}>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal className='profilemodal' show={show3} onHide={handleClose3} centered backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\cross.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>{error}</p>
                        </div>
                        <div className='okaybtn' onClick={handleClose3}>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Login
