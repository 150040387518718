import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useEffect, useState } from 'react'
import useWeb3 from '../useWeb3';
import Environment from '../../utils/Environment';
import { preSaleContractHelper } from '../../utils/contractHelpers';
import useRefresh from './useRefresh';

// const LECTLatestTokenPrice = () => {
//     let { account } = useWeb3React();
//     const web3 = useWeb3();

//     const contractAddress = Environment?.PreSaleContract;
//     const contract = getPreSalePriceContractHelper(contractAddress, web3);

//     const getLECTLatestTokenPrice = useCallback(
//         async () => {
//             try {
//                 console.log('/ssssssssssss tokenPrice', contract.methods, contract.methods?.investmentRange);
//                 var response = await contract.methods.tokenPrice()?.call({ from: account })
//                 console.log(response, 'response, tokenPrice');
//             } catch (error) {
//                 console.log('catch error', error);
//             }
//             return response
//         },
//         [contract]
//     );
//     return { getLECTLatestTokenPrice: getLECTLatestTokenPrice };
// }


const LatestTokenPrice = () => {
    const [latestTokenPrice, setLatestTokenPrice] = useState(0)
    const web3 = useWeb3()
    let { account } = useWeb3React();

    const { fastRefresh } = useRefresh()
    const contractAddress = Environment?.preSaleContract;
    const contract = preSaleContractHelper(contractAddress, web3);

    useEffect(() => {
        const fetchBalance = async () => {
            var response = await contract.methods.tokenPrice()?.call({ from: account })
            setLatestTokenPrice(response / 10 ** 18)
            console.log('repnse:::: in sale Price', response)
        }
        fetchBalance()
    }, [contract, fastRefresh])

    return latestTokenPrice
}

export default LatestTokenPrice