import { useCallback, useEffect, useState } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { preSaleContractHelper } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import useRefresh from "./useRefresh";

const GlnTokenPrice = () => {
    const [glnbalance, setGlnBalance] = useState(0);
    const web3 = useWeb3();
    let { account } = useWeb3React();
    console.log("🚀 ~ file: getBalance.js:48 ~ GetBalance ~ account:", account);

    const { fastRefresh } = useRefresh();
    const contractAddress = Environment?.preSaleContract;
    const contract = preSaleContractHelper(contractAddress, web3);

    useEffect(() => {
        const fetchBalance = async () => {
            var response = await contract.methods.tokenPrice()?.call()
            console.log('repnse:::: in balanceOf gln price', response / 10 ** 18)
            setGlnBalance(response / 10 ** 18)
        }
        if (account) {
            console.log("🚀 ~ file: getBalance.js:61 ~ useEffect ~ account:", account)
            fetchBalance();
        }
    }, [contract, fastRefresh]);

    return glnbalance;
}

export default GlnTokenPrice