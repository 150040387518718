import React, { useEffect, useState } from "react";
import "./login.scss";

import { Link } from "react-router-dom";

const Successfullogin = ({ setToken }) => {




    return (
        <>

            <section className="main-login">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-10 col-sm-12 m-auto">
                            <div className="backtopbtn">
                                <Link to="/" className="back-btn">
                                    <img src="\assets\login\arrow-left.svg" alt="img" className="img-fluid" />  Back

                                </Link>
                            </div>
                            <div className="maincard">
                                <div className="logincard">
                                    <div className="innerlogo">
                                        <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                                    </div>
                                    <div className="innerlogosuccess ">
                                        <img src="\assets\login\Milestones of business projects-cuate 1.svg" alt="img" className="img-fluid" />
                                    </div>
                                    <div className="cardtext">
                                        <h6>Success!</h6>
                                        <p>
                                            Your Password has been successfuly changed
                                        </p>
                                    </div>
                                    <Link to="/loginone">
                                        <div className="endbtnsus">
                                            <button className="cussloginbtns">Log In</button>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Successfullogin 
