import { create } from "ipfs-http-client";
const projectId = "2E6wz7GkHGNj2VCFy7gyPnayjGj";
const projectSecret = "1063222031887d57279e615d89af9006";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");
const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

export const IpfsStorage1 = async (file) => {
  try {
    const addImage = await client.add(file)
    // const imageUrl = `ipfs://${addImage.path}`;
    // const imageUrl = `https://ipfs.io/ipfs/QmYpvSb972QpqsMWcq5tPF2UB25NG5HcUoDg9fmCRoa7RS/${addImage.path}`;
    const imageUrl = `https://ipfs.io/ipfs/${addImage.path}`;
    return imageUrl;
  } catch (error) {
    // // console.log('Error uploading file: ', error)
  }
}