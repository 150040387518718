import React from 'react'
import Navbar from '../header/Navbar'
import Footer from '../../Footer/Footer'
import { Link } from 'react-router-dom'

const Commingsoon = () => {


    return (
        <>
            {/* <Navbar /> */}

            <section className="main-Commingsoon">
            <div className="custom-container">
                    <div className="row">
                        <div className="col-12 col-sm-12 m-auto p-0">
                            <div className="maincard-soon">
                         
                                    <div className="innerlogo">
                                        <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                                    </div>
                                    <div className="cardtext">
                                        <img src="\assets\Coming Soon.svg" alt="img" className="img-fluid" />
                                    
                                    </div>
                                    <Link to="/">
                                            <button className="comminghsoonbackbtn">
                                                <img src="\assets\login\arrow-left.svg" alt="img" className="img-fluid" />  Back to home
                                            </button>
                                        </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </>
    )
}

export default Commingsoon
