import React from 'react'
import "./faqs.scss"
import Accordion from 'react-bootstrap/Accordion';
const Faqs = () => {
    return (
        <>
            <section id='Faqs' className='faqs'>
                <div className="custom-container">
                    <div className='faqsheads'>
                        <h2>Frequently Asked Questions</h2>
                     
                    </div>
                    <div className='faqsacc'>
                        <Accordion 
                        >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                   <h2 className='question'>Q1. What is Goldenloonie?</h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>GoldenLoonie is an innovative blockchain-driven investment platform that opens the door to dynamic investment opportunities  through tangibly backed assets.in real estate listed on the platform.</p>
                               
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion >
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                   <h2 className='question'>Q2. Is wallet connect necessary for investing? </h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p> Yes, a digital wallet connection is required to securely make a transaction and hold your tokens. It's an essential tool for interacting with our dApp and the blockchain ecosystem.</p>
                               
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                   <h2 className='question'>Q3.  How do I connect my wallet? </h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p> Login to your dashboard and select "Connect wallet". From there, choose your preferred wallet and follow the on-screen instructions to ensure a secure connection.</p>
                               
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                   <h2 className='question'>Q4. How can I monitor my investments? </h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>You can track your investments day-to-day directly through our platform, giving you a continuous overview of your portfolio's performance. </p>
                               
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {/* <Accordion>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                   <h2 className='question'>Q5. Itaque earum rerum hic tenetur a sapiente delectus?</h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                               
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                        </div>
                </div>
            </section>
        </>
    )
}

export default Faqs