import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
const Footer = () => {
    return (
        <>
            <section className="main-footer ">
                <div className="custom-container">
                    <div className="row">
                        <div className="col-xl-12  m-auto padd-sm">
                            <div className='last-footertop'>
                                <div className=''>
                                    <Link to="/">
                                        <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                                    </Link>
                                    <p className='fotr-para'>
                                    Build your financial empire with Goldenloonie! 
                                    </p>
                                </div>

                                <ul className="navbarnavmr-auto dvsgd">
                                    <Link to="/">
                                        <li class="icon-butns">
                                            <img
                                                src="\assets\footer\icons1.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </li>
                                    </Link>
                                    <Link to="/marketplace">
                                        <li class="icon-butns">
                                            <img
                                                src="\assets\footer\icons2.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </li>
                                    </Link>
                                    <Link to="/">
                                        <li class="icon-butns">
                                            <img
                                                src="\assets\footer\icon3.svg"
                                                alt="img" className="img-fluid " />
                                        </li>
                                    </Link>
                                    <Link to="/profile">
                                        <li class="icon-butns">
                                            <img src="\assets\footer\icons4.svg" alt="img" className="img-fluid " />
                                        </li>
                                    </Link>
                                    <Link to="/profile">
                                        <li class="icon-butns">
                                            <img
                                                src="\assets\footer\icons5.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </li>
                                    </Link>
                                    <Link to="/profile">
                                        <li class="icon-butns">
                                            <img src="\assets\footer\icons6.svg" alt="img" className="img-fluid " />
                                        </li>
                                    </Link>

                                </ul>

                            </div>

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-xl-12 m-auto'>
                            <div className="inner-footer-end ">
                                <p className="right-paras">© 2023 Goldenlooni, all rights reserved.</p>
                                <p className='right-paras'>
                                    Terms of use  |  Privacy policy
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    )
}

export default Footer;
