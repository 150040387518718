import React from 'react'
import "./settingreset.scss"
import { Link } from 'react-router-dom'
const Settingreset = () => {
    return (
        <>
            <section className='resetpassword'>
                <div className="custom-container">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='backbutton'>
                                <Link to="/"><img src='\assets\arrow-left.svg' alt='img' className='img-fluid' />Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-7 m-auto'>
                            <div className='resetpassword'>
                                <div className='maincard'>
                                    <div className='resethead'>
                                        <h2>Reset your Password</h2>
                                        <p>Your new password must be different  from previously used passwords.</p>
                                    </div>
                                    <div className='maininput'>
                                        <input type='text' placeholder='Current Password' className='iferrorshow' />
                                        <img src='\assets\eye.svg' alt='img' className='img-fluid eye' />
                                        <p className='red'>Your Current password is incorrect</p>
                                    </div>
                                    <div className='maininput'>
                                        <input type='text' placeholder='New Password' className='' />
                                        <img src='\assets\eye.svg' alt='img' className='img-fluid eye' />
                                    </div>
                                    <div className='passwordmust'>
                                        <h4>Password Must:</h4>
                                        <ul className=''>
                                            <li>Be at least 8 characters long</li>
                                            <li className='greens'>Have at least one uppercase</li>
                                            <li className='redss'>Contain at least one special character</li>
                                        </ul>
                                    </div>
                                    <div className='maininputs'>
                                        <input type='text' placeholder='New Password' />
                                        <img src='\assets\eye.svg' alt='img' className='img-f;uid eye' />
                                    </div>
                                    <Link to="entercode">
                                    <div className='confirmbtn'>
                                        <button>Confirm</button>
                                      
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Settingreset