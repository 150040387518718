import React from 'react'
import "./investproperty.scss"
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Link } from 'react-router-dom';
import Navbar from '../landing/header/Navbar';
const Investproperty = () => {
    return (
        <>
        <Navbar />
            <section className='main-invest'>
                <div className='investcard'>
                    <div className='investimages'>
                        <img src='\assets\investimg.svg' alt='img' className='imginner' />
                    </div>
                    <div className='innertext'>
                        <div className='left'>
                            <h3>Goldenloonie 7751 Mallard Dr St. Louis Security Token</h3>
                        </div>
                        <Link t0 ="/profile">
                        <div className='right'>
                            <button className='invest'>Invest</button>
                        </div>
                        </Link>
                    </div>
                    <div className='parent-equity'>
                        <div className='eyuity'>
                            <div className='left'>
                                <img src='\assets\equity.svg' alt='img' className='img-fluid' />
                            </div>
                            <div className='right'>
                                <h6>Equity</h6>
                            </div>
                        </div>
                        <div className='dotimg'>
                            <img src='\assets\dots.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='eyuity'>
                            <div className='left'>
                                <img src='\assets\binance.svg' alt='img' className='img-fluid' />
                            </div>
                            <div className='right'>
                                <h6>Binance Mainet</h6>
                            </div>
                        </div>
                        <div className='dotimg'>
                            <img src='\assets\dots.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='eyuity'>
                            <div className='left'>
                                <img src='\assets\mainet.svg' alt='img' className='img-fluid' />
                            </div>
                            <div className='right'>
                                <h6>Binance Mainet</h6>
                            </div>
                        </div>

                    </div>
                    <div className='token'>
                        <div className='left'>
                            <h3>Security token price - <span>$50.00</span></h3>
                        </div>
                        <div className='rigt'>
                            <h3>Minimum investment size -<span> $50.00</span></h3>
                        </div>

                    </div>
                    <div className='progrss'>
                        <p>This chart represents the progress of the security token offering: collected funds vs. target amount.</p>
                        <ProgressBar now={80} />
                    </div>
                    <div className='amount'>
                        <div className='amountleft'>
                            <p>Amount Raised:</p>
                            <h6>$49,055.52</h6>
                        </div>
                        <div className='amountright'>
                            <p>Offering Size</p>
                            <h6>$ 136,800</h6>
                        </div>
                    </div>
                    <Link to="/profile">
                    <div className='endbtnss d-none'>
                        <button className='invest'>Invest</button>
                    </div>
                    </Link>
                </div>
                <div className='informationcard'>
                    <div className='main_heading'>
                        <h2>Information</h2>
                    </div>
                    <div className='parent'>
                        <div className='left-side set-custom-border-left'>
                            <div className='inner-text'>
                                <p>Country</p>
                                <h6>United States</h6>
                            </div>
                            <div className='inner-text'>
                                <p>Offering</p>
                                <h6>Private</h6>
                            </div>
                            <div className='inner-text'>
                                <p>SPV company name</p>
                                <h6>Goldenloonie Holdings LLC</h6>
                            </div>
                            <div className='inner-text'>
                                <p>Dividends</p>
                                <h6>Yes</h6>
                            </div>
                            <div className='inner-text'>
                                <p>Revenue share</p>
                                <h6>Revenue share</h6>
                            </div>
                        </div>
                        <div className='left-side'>
                            <div className='inner-text'>
                                <p>Date</p>
                                <h6>Jun 21, 2022 - Dec 31, 2023</h6>
                            </div>
                            <div className='inner-text'>
                                <p>Equity</p>
                                <h6>Yes</h6>
                            </div>
                            <div className='inner-text'>
                                <p>Redemption</p>
                                <h6>No</h6>
                            </div>
                            <div className='inner-text'>
                                <p>Voting rights</p>
                                <h6>No</h6>
                            </div>
                            <div className='inner-text'>
                                <p>Voting rights</p>
                                <h6>No</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Investproperty