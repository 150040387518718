import React from 'react'
import "./buytoken.scss"
import { HashLink } from 'react-router-hash-link'
const Buytoken = ({ hashId }) => {
    return (
        <>
            <section className='buytoken'>
                <div className="custom-container">
                    <div className='row     column-reverse'>
                        <div className='col-xl-6 col-md-12 col-12 padd-sm'>
                            <div className='buytokenimage'>
                                <img src='\assets\buytoken.svg' alt='img' className=' img-fluid'></img>
                            </div>
                        </div>
                        <div className='col-xl-6 col-md-12 col-12 padd-sm'>
                            <div className='main-head'>
                                <h2>More than Investment, We’re Crafting Destinies! </h2>
                                <p>Crafting dreams & future of Investment , Goldenloonie is a  revolutionary blockchain project aiming to provide tangible assets for your investment.

                                </p>
                                {/* <p> quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi</p> */}
                            </div>
                            {/* <div className='buybutton'>
                                <HashLink to={`#${hashId}`}>
                                    <button className='login1'>
                                        buy token
                                    </button>
                                </HashLink>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Buytoken