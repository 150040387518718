import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { preSaleTokenContractHelper } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

export const Allowance = () => {

    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = environment.preSaleToken;
    const preSaleContractAddress = environment.preSaleContract;
    const contract = preSaleTokenContractHelper(contractAddress, web3);

    const checkAllowance = useCallback(async () => {
        console.log(preSaleContractAddress, account, "addressss");
        try {
            const details = await contract.methods
                .allowance(account,preSaleContractAddress)
                .call()
            console.log('error of the allowance---contract', details)
            return details

        } catch (error) {
            // // console.log('error of the allowance---contract-error', error)
            throw error;

        }
    }, [contract, account]);

    return { checkAllowance: checkAllowance };
};

export default Allowance;
