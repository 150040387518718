import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForPassword from './components/ForPassword/ForPassword';
import Login from './components/Login/Login';
import Forgotpassword from './components/Forgotpassword/Forgotpassword';
import Loginone from './components/Login/Loginone';
import Forgetpassword from './components/Login/Forgetpassword';
import Creatnewpassword from './components/Login/Creatnewpassword';
import Successfullogin from './components/Login/Successfullogin ';
import Signup from './components/Login/Signup';
import Footer from './components/Footer/Footer';
import Setting from './components/Setting/Setting';
import Settingreset from './components/Settingreset/Settingreset';
import Enter2fa from './components/Enter2fa/Enter2fa';
import Passwordsuccess from './components/Passwordsuccess/Passwordsuccess';
import Propertydetail from './components/Propertydetail/Propertydetail';
import Navbar from './components/landing/header/Navbar';
import Currentproperties from './components/Currentproperties/Currentproperties';
import Dashboardpanel from './components/Dashboardpanel/Dashboardpanel';
import Investproperty from './components/Investproperty/Investproperty';
import Commingsoon from './components/landing/Mainbanner/Commingsoon';
import useEagerConnect from './hooks/useEagerConnect';
import Golden from './components/Golden/Golden';
import { useWeb3React } from '@web3-react/core';
// import Loader from './components/loader/Loader';


function App() {

  useEagerConnect();


  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        {/* <Navbar/> */}
        {/* <Loader /> */}
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/forpassword' component={ForPassword} />
          {/* <Route exact path='/' component={Login} /> */}
          <Route exact path='/loginone' component={Loginone} />

          <Route exact path='/forgetpassword' component={Forgetpassword} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/login' component={Login} />

          <Route exact path='/creatnewpassword/:token' component={Creatnewpassword} />
          <Route exact path='/successfullogin' component={Successfullogin} />
          <Route exact path='/propertydetail' component={Propertydetail} />
          <Route exact path='/currentproperties' component={Currentproperties} />
          <Route exact path='/commingsoon' component={Commingsoon} />

          <Route exact path='/forgotpassword' component={Forgotpassword} />
          <Route exact path='/setting' component={Setting} />
          <Route exact path='/reset' component={Settingreset} />
          <Route exact path='/entercode' component={Enter2fa} />
          <Route exact path='/success' component={Passwordsuccess} />
          <Route exact path='/golden' component={Golden} />
          {/* dashboard panel here....................... */}
          <Route exact path='/dashboardpanel' component={Dashboardpanel} />
          <Route exact path='/invest' component={Investproperty} />
        </Switch>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
