import web3NoAccount from "./web3";
import preSaleTokenAbi from './preSaleTokenAbi.json';
import preSaleContractAbi from './preSaleContractAbi.json';
import glnTokenAbi from './glnTokenAbi.json';

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi, address);
};

export const preSaleTokenContractHelper = (address, web3) => {
  return getContract(preSaleTokenAbi, address, web3);
};

export const preSaleContractHelper = (address, web3) => {
  return getContract(preSaleContractAbi, address, web3);
};

export const glnTokenContractHelper = (address, web3) => {
  return getContract(glnTokenAbi, address, web3);
};