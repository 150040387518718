import React from 'react';
import './loader.css';

const Loader = () => {
    return (
        <div className='loader-wrapper'>
            <span class="loader"></span>
        </div>
    )
}

export default Loader