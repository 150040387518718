import React, { useState } from "react";
import "./login.scss";

import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";

const Creatnewpassword = () => {

    const { token } = useParams();
    console.log("🚀 ~ file: Creatnewpassword.js:9 ~ Creatnewpassword ~ token:", token)
    const history = useHistory();

    const [fPassword, setFPassword] = useState(null);
    const [cfPassword, setCFPassword] = useState(null);
    const [fPasswordAPiRes, setFPasswordAPiRes] = useState(false);
    const [showP, setShowP] = useState(false);
    const [showP1, setShowP1] = useState(false);

    console.log("🚀 ~ file: Creatnewpassword.js:17 ~ Creatnewpassword ~ fPasswordAPiRes:", fPasswordAPiRes)

    const payload = {
        password: fPassword,
        resetToken: token
    }

    const forgetPassHandle = () => {
        if (!fPassword) {
            toast?.error("Plase enter password")
            return
        }

        else if (!cfPassword) {
            toast?.error("Plase enter Confitm password")
            return
        }

        else if (fPassword === cfPassword) {
            axios.post(`${API_URL}/v1/auth/reset-password`, payload)
                .then(response => {
                    console.log(response.data, 'changes passwoard ..........');
                    // setResponseData(response.data);
                    if (response.data) {
                        // localStorage.setItem('userId', response?.data?.user?.id)
                        history.push('/success');
                        setFPasswordAPiRes(true);
                    }
                    // if (response.data.msg === "Wallet address must be unique.") {
                    //     toast.error(response.data.msg);
                    // }
                    // else {
                    //     toast.success(response.data.msg);
                    // }

                })
                .catch((error) => {
                    console.error('passwoard Error:::::::::::::::::::>>>>>>>>>>>>>>>>', error);
                    toast.error(error);
                });
        } else {
            toast?.error("Confirm password dose not match")
        }
    };


    return (
        <>

            <section className="main-login">
                <div className="container-fluid ">

                    <div className="row">
                        <div className="col-10 col-sm-12 m-auto">
                            <div className="backtopbtn">
                                <Link to={'/forgetpassword'}>
                                    <button className="back-btn">
                                        <img src="\assets\login\arrow-left.svg" alt="img" className="img-fluid" />  Back
                                    </button>
                                </Link>
                            </div>

                            <div className="maincard">
                                <div className="logincard">
                                    <div className="innerlogo">
                                        <img src="\assets\logo.svg" alt="img" className="img-fluid" />
                                    </div>
                                    <div className="cardtext">
                                        <h6>Create New Password</h6>
                                        <p>
                                            Your new password must be different  from previously <br></br>used passwords.
                                        </p>

                                        <div className="parent">
                                            <input
                                                placeholder="New Password"
                                                type={showP ? "text" : "password"}
                                                value={fPassword}
                                                onChange={(e) => setFPassword(e?.target?.value)}
                                            />
                                            <img
                                                src="\assets\login\eye.svg"
                                                className="img-fluid eye-btn"
                                                alt="img"
                                                onClick={() => setShowP(!showP)}
                                            />
                                        </div>
                                        <ul className="passwordmust ">
                                            <h6 className="paswodheadng" >Password Must:</h6>
                                            <li className="text-wrng" >
                                                <img
                                                    src="\assets\login\dott.svg"
                                                    className="img-fluid dottss"
                                                    alt="img"
                                                />
                                                Be at least 8 characters long</li>
                                            <li className="text-wrng" >  <img
                                                src="\assets\login\dott.svg"
                                                className="img-fluid dottss"
                                                alt="img"
                                            />
                                                Have at least one uppercase</li>
                                            <li className="text-wrng" >
                                                <img
                                                    src="\assets\login\dott.svg"
                                                    className="img-fluid dottss"
                                                    alt="img"
                                                />
                                                Contain at least one special character</li>
                                        </ul>
                                        <div className="parent ">
                                            <input
                                                placeholder="Confirm New Password"
                                                type={showP1 ? "text" : "password"}
                                                value={cfPassword}
                                                onChange={(e) => setCFPassword(e?.target?.value)}
                                            />
                                            <img
                                                src="\assets\login\eye.svg"
                                                className="img-fluid eye-btn"
                                                alt="img"
                                                onClick={() => setShowP1(!showP1)}
                                            />
                                        </div>

                                    </div>

                                    {/* <Link to="/loginone"> */}
                                    <div className="endbtn" onClick={forgetPassHandle}>
                                        <button >Confirm</button>
                                    </div>
                                    {/* </Link> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Creatnewpassword
