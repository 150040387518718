import React from 'react'
import "./banner.scss"
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

const Banner = () => {
    return (
        <>
            <section className='main-banner'>
                <div className="custom-container">
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-12 padd-sm'>
                            <div className='mainheading'>
                                <h2><span>Unveiling the Future </span>of Smart Investment with Crypto!</h2>
                                <p>We’re the architect of building financial empire with a vision to reshape investment landscapes using the prowess of blockchain technology. </p>
                            </div>
                            <div className='mainbtn'>
                                <NavHashLink to={'#with-hash-fragment'}>
                                    <button className=' login1'>Invest Now</button>
                                </NavHashLink>
                           
                            </div>
                        </div>
                        <div className='col-sxl-6 col-lg-6 col-12 m-auto padd-sm'>
                            <div className='bannerimage'>
                                <img src='\assets\Group.svg' alt='img' className='img-fluid innerimage' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner