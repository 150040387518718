import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useEffect, useState } from 'react'
import useWeb3 from '../useWeb3';
import Environment from '../../utils/Environment';
import { glnTokenContractHelper } from '../../utils/contractHelpers';
import useRefresh from './useRefresh';


const GetFundLimit = () => {

    const [getFundLimitPrice, setGetFundLimitPrice] = useState(0)
    const web3 = useWeb3()
    let { account } = useWeb3React();

    const { fastRefresh } = useRefresh()
    const contractAddress = Environment?.glnToken;
    const contract = glnTokenContractHelper(contractAddress, web3);

    useEffect(() => {
        const fetchBalance = async () => {
            var response = await contract.methods.maxLimit()?.call({ from: account })
            let a = (response / 10 **18)
            console.log('repnse:::: in sale getFundEndlimit', a)
            setGetFundLimitPrice(a)
        }
        fetchBalance()
    }, [contract, fastRefresh])

    return getFundLimitPrice
}

export default GetFundLimit