import React, { useEffect, useState } from 'react'
import './dashboardpanel.scss'
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Dropdown } from 'react-bootstrap';
import Dashbord from '../Dashbord/Dashbord';
import Property from '../Property/Property';
import Buydashbord from '../Buydashbord/Buydashbord';
import Selldashbord from '../Selldashbord/Selldashbord';
import { Link } from 'react-router-dom';
import Navbar from '../landing/header/Navbar';
// import Launchpad from '../Launchpad/Launchpad';
// import Modal from 'react-bootstrap/Modal';

const Dashboardpanel = () => {


    const [show6, setShow6] = useState(false);

    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    useEffect(() => {

        window.scrollTo(0, 0)
    }, [])
    const [showSide, setShowSide] = useState();

    const sidebar = () => {
        console.log("adfdssdf");
        if (showSide === true) {
            setShowSide(false);
        } else {
            setShowSide(true);
        }
    };


    



    return (
        <>
        <div className="wrapper-navbarrr">
            <Navbar />
            <section className="maindashboard">
                <div className="theme-container cdhvcvdvycvdv" >
                    <div onClick={sidebar} className="sidebar-icon">
                        <img src="\assets\mobilelogo.svg" alt="mobilelogo" className="mobilelogo d-none" />
                        <img
                            src="\assets\menu.svg"
                            alt="img"
                            className="img-fluid toggler"
                        />
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="lowerdashboardmain">
                            <div
                                className={
                                    showSide === true
                                        ? "mobielSidebarShow"
                                        : showSide === false
                                            ? "mobielSidebarHide "
                                            : "mobile-side-none"
                                }
                            >
                                <div className="sidebarheader d-none">
                                    <img src="\assets\mobilelogo.svg" alt="sidebarheaderlogo" className="sidebarheaderlogo" />
                                    <img
                                        src="\assets\menu.svg"
                                        alt="img"
                                        className="img-fluid close-btn d-none"
                                        onClick={sidebar}
                                    />
                                </div>
                                <Nav variant="pills" className="sidebar">
                                    <Link to="/">
                                        {/* <img src="\assets\dashbordlogo.svg" alt="logoside" className="logoside" /> */}
                                    </Link>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" onClick={sidebar}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z" fill="#EAB721" />
                                                <path d="M18.6699 2H16.7699C14.5899 2 13.4399 3.15 13.4399 5.33V7.23C13.4399 9.41 14.5899 10.56 16.7699 10.56H18.6699C20.8499 10.56 21.9999 9.41 21.9999 7.23V5.33C21.9999 3.15 20.8499 2 18.6699 2Z" fill="#EAB721" />
                                                <path d="M18.6699 13.43H16.7699C14.5899 13.43 13.4399 14.58 13.4399 16.76V18.66C13.4399 20.84 14.5899 21.99 16.7699 21.99H18.6699C20.8499 21.99 21.9999 20.84 21.9999 18.66V16.76C21.9999 14.58 20.8499 13.43 18.6699 13.43Z" fill="#EAB721" />
                                                <path d="M7.24 13.43H5.34C3.15 13.43 2 14.58 2 16.76V18.66C2 20.85 3.15 22 5.33 22H7.23C9.41 22 10.56 20.85 10.56 18.67V16.77C10.57 14.58 9.42 13.43 7.24 13.43Z" fill="#EAB721" />
                                            </svg>
                                            Dashboard
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="second" onClick={sidebar}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6 4.15C10.6 4.34 10.44 4.5 10.25 4.5H9.12C6.96 4.5 5.2 6.26 5.2 8.42V17.65C5.2 17.84 5.04 18 4.85 18H4.15C2.96 18 2 17.04 2 15.85V4.15C2 2.96 2.96 2 4.15 2H8.45C9.64 2 10.6 2.96 10.6 4.15Z" fill="#3E3E3E" />
                                                <path d="M22 4.15V15.85C22 17.04 21.04 18 19.85 18H19.22C19.03 18 18.87 17.84 18.87 17.65V8.42C18.87 6.26 17.11 4.5 14.95 4.5H13.75C13.56 4.5 13.4 4.34 13.4 4.15C13.4 2.96 14.36 2 15.55 2H19.85C21.04 2 22 2.96 22 4.15Z" fill="#3E3E3E" />
                                                <path d="M14.95 6H9.12001C7.78001 6 6.70001 7.08 6.70001 8.42V19.58C6.70001 20.92 7.78001 22 9.12001 22H10.75C11.03 22 11.25 21.78 11.25 21.5V19C11.25 18.59 11.59 18.25 12 18.25C12.41 18.25 12.75 18.59 12.75 19V21.5C12.75 21.78 12.97 22 13.25 22H14.96C16.29 22 17.37 20.92 17.37 19.59V8.42C17.37 7.08 16.29 6 14.95 6ZM14 14.75H10C9.59001 14.75 9.25001 14.41 9.25001 14C9.25001 13.59 9.59001 13.25 10 13.25H14C14.41 13.25 14.75 13.59 14.75 14C14.75 14.41 14.41 14.75 14 14.75ZM14 11.75H10C9.59001 11.75 9.25001 11.41 9.25001 11C9.25001 10.59 9.59001 10.25 10 10.25H14C14.41 10.25 14.75 10.59 14.75 11C14.75 11.41 14.41 11.75 14 11.75Z" fill="#3E3E3E" />
                                            </svg>
                                            Properties
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="third" onClick={sidebar}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#3E3E3E" />
                                            </svg>
                                            Buy
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="fourth" onClick={sidebar}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#3E3E3E" />
                                            </svg>
                                            Sell
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    {/* <div className='buttonend'>
                                        <button onClick={handleShow6}>Connect Wallet</button>
                                    </div> */}
                                </Nav>
                            </div>
                            <Tab.Content className="content-side">


                                <Tab.Pane eventKey="first">
                                    {/* <Launchpad /> */}
                                    <Dashbord />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    {/* <Launchpad /> */}
                                    <Property />
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    {/* <Launchpad /> */}
                                    <Buydashbord />
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    {/* <Launchpad /> */}
                                    <Selldashbord />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>

                    </Tab.Container>
                </div>
            </section>

            </div>

        </>
    )
}

export default Dashboardpanel