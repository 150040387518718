import React from 'react'
import "./golden.scss"
import Navbar from '../landing/header/Navbar'
import Footer from '../Footer/Footer'
const Golden = () => {
    return (
        <>
            <Navbar />
            <section className='golden'>
                <div className="custom-container">
                    <div className='mainheadss'>
                        <h4>GoldenLoonie isn't just another token—it's a revolution in real estate investment. As the digital frontier reshapes traditional markets, real estate is no exception. Our platform provides a unique blend of blockchain technology and real estate expertise, ensuring that you're not only investing in a token but in a future-proof financial strategy.</h4>
                    </div>
                    <div className='inner-mainhead'>
                        <h2>Why Choose GoldenLoonie?</h2>
                    </div>
                    <div className='parenthead'>
                        <h3>Secure Transactions</h3>
                        <p>Employing state-of-the-art blockchain technology, every transaction you make is encrypted, transparent, and tamper-proof.</p>
                    </div>
                    <div className='parenthead'>
                        <h3>Real Estate Expertise</h3>
                        <p>Our team comprises seasoned professionals from the real estate sector, ensuring that your investments are guided by industry insights and deep market knowledge.</p>
                    </div>
                    <div className='parenthead'>
                        <h3>Global Reach</h3>
                        <p>No longer bound by geographical constraints, invest in prime properties across the globe with just a few clicks.</p>
                    </div>
                    <div className='parenthead'>
                        <h3>Liquidity</h3>
                        <p>Easily buy, sell, or trade your tokens in a liquid market, giving you the flexibility to cash out or reinvest as you see fit.</p>
                    </div>
                    <div className='parenthead'>
                        <h3>Community</h3>
                        <p>Become a part of a thriving network of investors, sharing insights, opportunities, and success stories..</p>
                    </div>
                    <div className='parenthead'>
                        <h3>Sustainability</h3>
                        <p>Our commitment extends beyond profits. We're dedicated to eco-friendly initiatives, ensuring that our projects contribute positively to the environment and communities.Join us and redefine what's possible in the real estate sector!</p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Golden