import React from 'react'
import "./currentproperty.scss"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Investors from './Investors';
import PreSaleEndTime from '../../hooks/dataFetchers/preSaleEndTime';
import Countdown from 'react-countdown';
import { useState } from 'react';
import { useEffect } from 'react';



const Currentproperty = ({ hashId }) => {
    // const [preSale, setPreSale] = useState(null);


    // const { preSaleETime } = PreSaleEndTime();
    // const GetTime = async () => {
    //     console.log("!!!!2")
    //     let pSaleTime = preSaleETime();
    //     console.log("!!!!", pSaleTime)
    //     // try {
    //     //     // let preSaleEndTime = PreSaleEndTime();
    //     //     setPreSale(true)
    //     // } catch (err) {
    //     //     console.log("!!!!", err)
    //     // }
    // }


    // useEffect(() => {
    //     GetTime()
    // }, [])


    // const Completionist = () => <span>You are good to go!</span>;
    // // Renderer callback with condition
    // const renderer = ({ days, hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //         // Render a completed state
    //         return <Completionist />;
    //     } else {
    //         // Render a countdown
    //         // setDaysS(seconds)
    //         return (
    //             // <span>{days}D : {hours}H : {minutes}M : {seconds}S </span>
    //             <div className='d-flex w-50 justify-content-end justify-content-evenly'>
    //                 <div className='inner'>
    //                     <p className='green'>{days}</p>
    //                     <p className='grey'>Days</p>
    //                 </div>
    //                 <span>:</span>
    //                 <div className='inner'>
    //                     <p className='green'>{hours}</p>
    //                     <p className='grey'>Hours</p>
    //                 </div>
    //                 <span>:</span>
    //                 <div className='inner'>
    //                     <p className='green'>{minutes}</p>
    //                     <p className='grey'>MINUTES</p>
    //                 </div>
    //                 <span>:</span>
    //                 <div className='inner'>
    //                     <p className='green'>{seconds}</p>
    //                     <p className='grey'>Seconds</p>
    //                 </div>
    //             </div>
    //         );
    //     }
    // };
    
    return (
        <>
            <section className='main-propertyn'>
                <div className="custom-container">
                    <div className='main_head d-flex justify-content-between'>
                        {/* <h2>Live Fund Investment</h2> */}
                        {/* <div className='d-flex align-items-center justify-content-between w-50'>
                            <h4 className='text-white'>PreSale End time</h4>
                            {preSale &&
                                <Countdown
                                    className='text-white'
                                    // date={ (parseInt(preSaleEndTime/1000))-(adtee.getTime()*1000)}
                                    date={Date.now() + 1693381020}
                                    renderer={renderer}
                                // autoStart={true}
                                />
                            }
                        </div> */}
                    </div>
                    <Investors hashId={hashId} />
                </div>
            </section>
        </>
    )
}

export default Currentproperty