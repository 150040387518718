import React from 'react'
import Navbar from '../landing/header/Navbar'
import "./currentproperties.scss";
import { Link } from 'react-router-dom';



const Currentproperties = () => {

    return (

        <>
            <Navbar />
            <section className="main-currentproperties">
                <div className="custom-container ">
                    <div className="main-heading-div ">
                    <Link to="/Propertydetail">
                        <button className="back-top-btn">
                            <img src="\assets\login\arrow-left.svg" alt="img" className="img-fluid" />  Back
                        </button>
                        </Link>
                        <h1 className='main-deading' >
                            Current Properties
                        </h1>
                        <h1 className='' >
                        </h1>
                    </div>
                    <div className='row'>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\fst-cardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                Rental Property
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Cash return: 7.98%
                                            </h6>
                                            <h6 className='yellow_inerdig'>
                                                Token Price: <span>$50</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Sold Out
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\seccardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                House flip
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Est. ROI:<span className='yelo_iner'>9% - 17.4%</span>
                                            </h6>
                                            <h6 className='yellow_inerdig'>

                                                APR equivalent:
                                                <span className='yelo_iner'>27% - 52%</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Now Available
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\fst-cardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                Rental Property
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Cash return: 7.98%
                                            </h6>
                                            <h6 className='yellow_inerdig'>
                                                Token Price: <span>$50</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Sold Out
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\fst-cardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                Rental Property
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Cash return: 7.98%
                                            </h6>
                                            <h6 className='yellow_inerdig'>
                                                Token Price: <span>$50</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Sold Out
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\seccardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                House flip
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Est. ROI:<span className='yelo_iner'>9% - 17.4%</span>
                                            </h6>
                                            <h6 className='yellow_inerdig'>

                                                APR equivalent:
                                                <span className='yelo_iner'>27% - 52%</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Now Available
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\fst-cardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                Rental Property
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Cash return: 7.98%
                                            </h6>
                                            <h6 className='yellow_inerdig'>
                                                Token Price: <span>$50</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Sold Out
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\fst-cardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                Rental Property
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Cash return: 7.98%
                                            </h6>
                                            <h6 className='yellow_inerdig'>
                                                Token Price: <span>$50</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Sold Out
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\seccardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                House flip
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Est. ROI:<span className='yelo_iner'>9% - 17.4%</span>
                                            </h6>
                                            <h6 className='yellow_inerdig'>

                                                APR equivalent:
                                                <span className='yelo_iner'>27% - 52%</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Now Available
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-12 m-auto mb-4 padd-sm'>
                            <div className='main-card'>
                                <div className='top_card_img'>
                                    <img src="\assets\properties\fst-cardimg.svg" alt="img" className="img-fluid card_img" />
                                </div>
                                <div className='card_inner_text'>
                                    <h5 className='card-inner-hedng' >
                                        817 12th Ave N
                                    </h5>
                                    <div className='card_inner_text_text'>
                                        <div className='card_inner_flex pb-2'>
                                            <h6 className='offertyp'>
                                                Offering type
                                            </h6>
                                            <h6 className='yellow_inerbtn'>
                                                Rental Property
                                            </h6>
                                        </div>
                                        <div className='card_inner_flex'>
                                            <h6 className='offertyp'>
                                                Cash return: 7.98%
                                            </h6>
                                            <h6 className='yellow_inerdig'>
                                                Token Price: <span>$50</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <h3 className='solld_oud'>
                                        Sold Out
                                    </h3>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
        </>
    )
}

export default Currentproperties
