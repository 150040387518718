import React, { useEffect } from 'react'
import "./setting.scss"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { IpfsStorage1 } from '../../hooks/ipfs1';
import { API_URL } from '../../utils/ApiUrl';
import placeholderImg from '../../Assests/placeholderImg.png';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import Navbar from '../landing/header/Navbar';

const Setting = () => {

    let { account } = useWeb3React();
    let localUserId = localStorage.getItem('userId');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


    const [show4, setShow4] = useState(false);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);


    const [show5, setShow5] = useState(false);

    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);


    const [show6, setShow6] = useState(false);

    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    const [usernameS, setUsernameS] = useState(null);


    const [logoPicture1, setLogoPicture] = useState(null);
    // console.log("🚀 ~ file: Create.js:304 ~ Create ~ logoPicture1:", logoPicture1)

    const handleFileChange = async (event) => {
        console.log('getting imgggg');
        const file = event.target.files[0];
        // console.log('get logo file..........................................', file);

        let resImg = await IpfsStorage1(file)
        // console.log(resImg, 'resImg resImg resImg');
        setLogoPicture(resImg);
        // setLogoPicture(URL.createObjectURL(evt.target.files[0]));
    };

    let payload = {
        userId: localUserId,
        name: usernameS,
        walletAddress: account,
        picture: logoPicture1
    }

    const createUserHandle = () => {
        axios.patch(`${API_URL}/v1/users/editUser`, payload)
            .then(response => {
                console.log(response.data, "asdasd");
                // setResponseData(response.data);
                // toast.success('User edit successfully')
                handleShow2();
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error(error)
            });
    };

    const clsHandle = () => {
        handleClose2();
        window?.location?.reload();
    }


    const [userData, setUserData] = useState(null);
    console.log("🚀 ~ file: Landing.js:23 ~ Landing ~ userData:", userData)

    // const { account } = useWeb3React();

    // let localUserId = localStorage.getItem('userId');


    const getUserHandle = async () => {
        try {
            const response = await axios.post(`${API_URL}/v1/users/getUser`, { _id: localUserId });

            if (response) {
                // Handle success
                setUserData(response.data);
                console.log('Request successful', response.data);
                setUsernameS(response?.data?.name);
                setLogoPicture(response?.data?.picture)
            } else {
                // Handle error
                console.error('Request failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        if (localUserId) {
            // createUserHandle();
            getUserHandle();
        } else {
        }
    }, [account])


    return (
        <><Navbar />
            <section className='setting'>
                <div className="custom-container">
                    <Link to={'/'}>
                        <div className='backbutton'>
                            <button><img src='\assets\arrow-left.svg' alt='img' className='img-fluid' />Back</button>
                        </div>
                    </Link>
                    <div className='mainhead'>
                        <h2>Settings</h2>
                    </div>
                    <div className='settingcard'>
                        <div className='settingparent'>
                            <div className='left'>
                                <div className='inner'>
                                    <div className='settingimage'>
                                        <img src={logoPicture1 ? logoPicture1 : placeholderImg} alt='img' className='innerimages' />
                                    </div>
                                    <div className='textimage'>
                                        <p>Profile Image</p>
                                    </div>
                                </div>
                            </div>
                            <div className='right'>
                                <button className='login' onClick={handleShow}>Change Profile</button>
                            </div>
                        </div>
                    </div>
                    {/* <div className='settingcard'>
                        <div className='settingparent'>
                            <Link to="/reset">
                                <div className='left'>
                                    <h4>Update your password </h4>
                                    <p>through the button below You will be redirected to a new page and must follow the instructions.</p>
                                </div>
                            </Link>
                            <div className='right'>
                                <button className='login' onClick={handleShow1}>Update password </button>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='settingcard'>
                        <div className='settingparent'>
                            <div className='left'>

                                <div className='google'>

                                    <h4>Google Authenticator</h4>
                                    <p><img src='\assets\close-circle.svg' alt='img' className='img-fluid' />Not Linked</p>
                                </div>

                                <p>Protect your account and transactions.</p>
                            </div>
                            <div className='right'>
                                <div class="toggle-switch">
                                    <input class="toggle-input" id="toggle" type="checkbox" />
                                    <label class="toggle-label" for="toggle"></label>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='settingcard'>
                        <div className='settingparent'>
                            <div className='left'>
                                <h4>Update your username</h4>
                                <p>{usernameS ? usernameS : "Update you username"}</p>
                            </div>
                            <div className='right'>
                                <button className='login' onClick={handleShow3}>Update Username</button>
                            </div>
                        </div>
                    </div>
                    {/* <div className='settingcard'>
                        <div className='settingparent'>
                            <div className='left'>
                                <h4>Update your email</h4>
                                <p>johndoe123@gmail.com</p>
                            </div>
                            <div className='right'>
                                <button className='login' onClick={handleShow4}>Update Email</button>
                            </div>
                        </div>
                    </div> */}
                    <div className='w-25 my-5 mx-auto hscvvscyvscyvc'>
                        <button className='login dvydccvdvcdvcd' onClick={createUserHandle}>Submit</button>
                    </div>
                </div>
            </section>

            <Modal className='profilemodal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Profile Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='para'>Profile Image</p>
                    <div className='wrapper'>
                        <label htmlFor='upload'>
                            {
                                logoPicture1 ?
                                    <img src={logoPicture1} alt='img' className='img-fluid' /> :
                                    <img src='\assets\gallery-add.svg' alt='img' className='img-fluid' />
                            }
                        </label>
                        <input type='file' id='upload' className='d-none' onChange={(evt) => handleFileChange(evt)} />
                    </div>
                    {/* <img src={logoPicture1} alt='' className='img-fluid w-50 h-50' /> */}
                    <div className='endbtn' onClick={handleClose}>
                        <button>Change Profile Image</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* <Modal className='profilemodal' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Disable Google Authenticator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='parentinputs'>
                        <input className='code' type='text' placeholder='_' />
                        <input className='code' type='text' placeholder='_' />
                        <input className='code' type='text' placeholder='_' />
                        <input className='code' type='text' placeholder='_' />
                        <input className='code' type='text' placeholder='_' />
                        <input className='code' type='text' placeholder='_' />
                    </div>
                    <div className='digitbtn'>
                        <button onClick={() => {
                            handleShow2();
                            handleClose1();
                        }}>2 digits left</button>
                    </div>
                </Modal.Body>
            </Modal> */}

            {/* <Modal className='profilemodal' show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Disable Google Authenticator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mainimage'>
                        <img src='\assets\successfully-done.svg' alt='img' className='img-fluid success' />

                    </div>
                    <div className='disabletext'>
                        <a onClick={() => {
                            handleShow3();
                            handleClose2();
                        }}>2Fa Code Disabled</a>
                    </div>
                </Modal.Body>
            </Modal> */}

            <Modal className='profilemodal' show={show3} onHide={handleClose3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update your username</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type='text' placeholder='New Username' value={usernameS} onChange={(e) => setUsernameS(e.target.value)} />
                    <div className='endbtn' onClick={handleClose3}>
                        <button>Done</button>
                    </div>
                </Modal.Body>

            </Modal>

            {/* <Modal className='profilemodal' show={show4} onHide={handleClose4} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='blance-image'>
                        <img src='\assets\wallet-balance.svg' alt='img' className='innerblnc' />
                    </div>
                    <div className='paraemail'>
                        <p>Are you sure you want to change your email?</p>
                    </div>
                    <div className='next'>
                        <button className='backbtn'>Back</button>
                        <button className='innerbtn' onClick={() => {
                            handleShow5();
                            handleClose4();
                        }
                        }>Confirm</button>
                    </div>
                </Modal.Body>

            </Modal> */}

            {/* <Modal className='profilemodal' show={show5} onHide={handleClose5} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='emailinput firstinput'>
                        <h6>New Email</h6>
                        <input type='text' placeholder='Enter new email' />
                    </div>
                    <div className='parentinputmodal'>
                        <div className='emailinput'>
                            <h6>New Email verification code</h6>
                            <input type='text' placeholder='Enter code' />
                        </div>
                        <a className='gold'>Get Code</a>
                        <p className='paratext'>Enter the 6-digit code sent to joh***@gmail.com</p>

                    </div>
                    <h6 className='security'>Security Verification</h6>
                    <div className='parentinputmodal'>
                        <div className='emailinput'>
                            <h6>Email verification code</h6>
                            <input type='text' placeholder='Enter code' />
                        </div>
                        <a className='gold'>Get Code</a>
                        <p className='paratext'>Enter the 6-digit code sent to joh***@gmail.com</p>

                    </div>
                    <div className='parentinputmodal'>
                        <div className='emailinput'>
                            <h6>Authenticator Code</h6>
                            <input type='text' placeholder='Enter code' />
                        </div>

                        <p className='paratext'>Enter the 6-digit code from Google Authenticator</p>

                    </div>
                    <div className='next'>
                        <button className='backbtn'>Back</button>
                        <button className='innerbtn' onClick={() => {
                            handleShow6();
                            handleClose5();
                        }
                        }>Confirm</button>
                    </div>
                </Modal.Body>
            </Modal> */}

            {/* <Modal className='profilemodal' show={show6} onHide={handleClose6} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enable Google Authenticator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='successfully'>
                        <div className='mainimagesss'>
                            <img src='\assets\successfullydone.svg' alt='img' className='done' />
                        </div>
                        <div className='innertextsss'>
                            <h6>Email changed successfully</h6>
                            <p>your email has changed successfully</p>
                        </div>
                    </div>
                </Modal.Body>

            </Modal> */}



            <Modal className='profilemodal' show={show2} onHide={handleClose2} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Invest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='invest_modalbody'>
                    <div className='parenttick'>
                        <div className='images'>
                            <img src='\assets\successtick.svg' alt='img' className='img-fluid' />
                        </div>
                        <div className='text'>
                            <p>User edit successfully</p>
                        </div>
                        <div className='okaybtn' onClick={clsHandle}>
                            <button>Okay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Setting