import React from 'react'
import "./property.scss"
const Property = () => {
  return (
    <div>
      <section className='main-property'>
      <div className='investmentcard'>
          <div className='row'>
            <div className='col-xl-4 col-sm-12'>
              <div className='innerinvestcard'>
                <div className='homeimage'>
                  <img src='\assets\homepic.svg' alt='img' className='innerhome'/>
                </div>
                <div className='lowertext'>
                <div className='texthead'>
                  <h2>817 12th Ave N</h2>
                </div>
                <div className='brdr'></div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
                    <div className='rental'>
                      <button>Rental Property</button>
                    </div>
                  </div>
                </div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
               <p>Token Price: <span>$50</span></p>
                  </div>
                </div>
                </div>
               
              </div>
            </div>
            <div className='col-xl-4 col-sm-12'>
            <div className='innerinvestcard'>
                <div className='homeimage'>
                  <img src='\assets\homepic.svg' alt='img' className='innerhome'/>
                </div>
                <div className='lowertext'>
                <div className='texthead'>
                  <h2>817 12th Ave N</h2>
                </div>
                <div className='brdr'></div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
                    <div className='rental'>
                      <button>Rental Property</button>
                    </div>
                  </div>
                </div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
               <p>Token Price: <span>$50</span></p>
                  </div>
                </div>
                </div>
               
              </div>
            </div>
            <div className='col-xl-4 col-sm-12'>
            <div className='innerinvestcard'>
                <div className='homeimage'>
                  <img src='\assets\homepic.svg' alt='img' className='innerhome'/>
                </div>
                <div className='lowertext'>
                <div className='texthead'>
                  <h2>817 12th Ave N</h2>
                </div>
                <div className='brdr'></div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
                    <div className='rental'>
                      <button>Rental Property</button>
                    </div>
                  </div>
                </div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
               <p>Token Price: <span>$50</span></p>
                  </div>
                </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div className='investmentcard mt-5'>
          <div className='row'>
            <div className='col-xl-4 col-sm-12'>
              <div className='innerinvestcard'>
                <div className='homeimage'>
                  <img src='\assets\homepic.svg' alt='img' className='innerhome'/>
                </div>
                <div className='lowertext'>
                <div className='texthead'>
                  <h2>817 12th Ave N</h2>
                </div>
                <div className='brdr'></div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
                    <div className='rental'>
                      <button>Rental Property</button>
                    </div>
                  </div>
                </div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
               <p>Token Price: <span>$50</span></p>
                  </div>
                </div>
                </div>
               
              </div>
            </div>
            <div className='col-xl-4 col-sm-12'>
            <div className='innerinvestcard'>
                <div className='homeimage'>
                  <img src='\assets\homepic.svg' alt='img' className='innerhome'/>
                </div>
                <div className='lowertext'>
                <div className='texthead'>
                  <h2>817 12th Ave N</h2>
                </div>
                <div className='brdr'></div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
                    <div className='rental'>
                      <button>Rental Property</button>
                    </div>
                  </div>
                </div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
               <p>Token Price: <span>$50</span></p>
                  </div>
                </div>
                </div>
               
              </div>
            </div>
            <div className='col-xl-4 col-sm-12'>
            <div className='innerinvestcard'>
                <div className='homeimage'>
                  <img src='\assets\homepic.svg' alt='img' className='innerhome'/>
                </div>
                <div className='lowertext'>
                <div className='texthead'>
                  <h2>817 12th Ave N</h2>
                </div>
                <div className='brdr'></div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
                    <div className='rental'>
                      <button>Rental Property</button>
                    </div>
                  </div>
                </div>
                <div className='propertytype'>
                  <div className='left'>
                    <h6>Offering type</h6>
                  </div>
                  <div className='right'>
               <p>Token Price: <span>$50</span></p>
                  </div>
                </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        
      </section>
    </div>
  )
}

export default Property