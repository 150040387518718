import React from 'react'
import "./selldashbord.scss";
const Selldashbord = () => {
  return (
    <>
      <section className='buy_sell'>
        <span className='only_shadow'>
        </span>
        <img src='\assets\buy-sell\Vector.svg' alt='img' className='buy_sellshade' />
        <div className="custom-container">
          <div className='row'>
            <div className='col-xl-6 col-md-12 m-auto p-0'>
              <div className='main_headouter_div'>
           
                <h2>Sell</h2>
                <div className="set-internal-img">
                <div className='swap_img'>
                    <img src='\assets\buy-sell\frame.svg' alt='img' className='img-fluid ' />
                  </div>
                <div className='inner_data'>
                  <div className='usdt'>
                    <img src='\assets\buy-sell\tether-seeklogo.com 1.svg' alt='img' className=' img-fluid innerbuy'></img>
                    <p className='innerpara'>
                      GLN
                    </p>
                  </div>
                  <div className='usdtss'>
                    <p className='balancepara'>
                      Balance: <span> 4,323.8765 USDT</span>
                    </p>
                    <h4 className='figur'>
                      0.00
                    </h4>
                  </div>
                </div>

                <div className='inner_data mt-2'>
                  <div className='usdt'>
                    <img src='\assets\buy-sell\GLN.svg' alt='img' className='img-fluid ' />

                    <p className='innerpara'>
                      USDT
                    </p>
                  </div>
                  <div className='usdtss'>
                    <p className='balancepara'>
                      Balance: <span> 0.00</span>
                    </p>
                    <h4 className='figur'>
                      0.00
                    </h4>
                  </div>
                </div>
                </div>
                <div className='last_paraouter_div'>
                  <div className='last_para'>
                    <p className='cost'>
                      Estimated TX cost:
                    </p>
                    <h6 className='dolos'>
                      $0.79
                    </h6>
                  </div>
                  <div className='last_para'>
                    <p className='cost'>
                      Estimated TX cost:
                    </p>
                    <h6 className='dolos'>
                      $0.79
                    </h6>
                  </div>
                </div>

                <div className='buybutton ' data-bs-toggle="modal" data-bs-target="#exampleModal2"
                >
                  <button className='login'>Sell GLN</button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
      <div className='buy-sellmodal2'>
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Sell GLN</h5>
                <button type="button p-0" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <img src='\assets\buy-sell\close-circle.svg' alt='img' className=' ' />

                </button>
              </div>
              <div class="modal-body">
                <img src='\assets\buy-sell\Purchased.svg' alt='img' className='img-fluid ' />
                <p className='modalpara'>
                  You have successfully purchased <span>  40,000 GLN  </span>for <span>  1.00 USDT </span>
                </p>
                <button className='login'>Buy GLN</button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Selldashbord