import React, { useEffect, useState } from 'react';
import "./dashbord.scss";
import { Link } from 'react-router-dom';
import Investors from '../Currentproperty/Investors';
import GetGlnTokenBlance from '../../hooks/dataFetchers/getGlnTokenBlance';
import { NavHashLink } from 'react-router-hash-link';
import axios from 'axios';
import ClaimComp from '../Currentproperty/ClaimComp';
import GlnTokenPrice from '../../hooks/dataFetchers/glnTokenPrice';
import { useWeb3React } from '@web3-react/core';
import { API_URL } from '../../utils/ApiUrl';
import useWeb3 from '../../hooks/useWeb3';
import GetFundLimit from '../../hooks/dataFetchers/getFundLimit';

const Dashbord = () => {

  let { account } = useWeb3React();
  console.log("🚀 ~ file: Dashbord.js:16 ~ Dashbord ~ account:  invesmentss ~ response:", account)
  const web3 = useWeb3();

  let glnBlance = GetGlnTokenBlance();
  let glnTokenPrice = GlnTokenPrice();
  console.log(glnTokenPrice, 'glnTokenPrice');
  const [invesmentsAry, setinvesmentsAry] = useState(null);
  let getFundEndlimit = GetFundLimit();

  const [stateOne, setStateOne] = useState(null);
  const [stateSecond, setStateSecond] = useState(null);
  console.log(stateOne / 10 ** 6, stateSecond / 10 ** 18, 'stateSecond');

  const fund = (e) => {
    console.log(invesmentsAry, 'invesmentsAry stateSecond');
    let ins = 0
    let tokke = 0
    e?.map((data) => {
      ins = ins + parseInt(data?.investment / 10 ** 6)
      tokke = tokke + parseInt(data?.tokensPurchashed / 10 ** 18)
    })
    setStateOne(ins);
    setStateSecond(tokke);
  }







  console.log("🚀 ~ file: Dashbord.js:22 ~ Dashbord ~ invesmentsAry:", invesmentsAry)
  const [claimsAry, setclaimsAry] = useState(null);

  const [bignum, setBignum] = useState(null);

  const [userData, setUserData] = useState(null);
  console.log("🚀 ~ file: Dashbord.js:25 ~ Dashbord ~ userData:", userData)

  let localUserId = localStorage.getItem('userId');

  const getUserHandle = async () => {
    try {
      const response = await axios.post(`${API_URL}/v1/users/getUser`, { _id: localUserId });

      if (response) {
        // Handle success
        setUserData(response.data);
        console.log('Request successful', response.data);
      } else {
        // Handle error
        console.error('Request failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



  useEffect(() => {
    getUserHandle();
  }, [account])

  const invesmentsHandle = async () => {
    console.log('start graph......');
    var data = JSON.stringify({
      query: `query MyQuery {
        purchaseds(where: {by: "${account}"}) {
          tokensPurchashed
          investment
        }
      }`,
    });
    var config = {
      method: "post",
      url: 'https://api.thegraph.com/subgraphs/name/saifdevblochain/goldenloonie',
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        console.log("🚀 ~ file: invesmentss ~ response:", response)
        setinvesmentsAry(response.data?.data?.purchaseds);
        fund(response.data?.data?.purchaseds);
      })
      .catch(function (error) {
        console.log("🚀 ~ file: Investors.js:88 ~ error: Investors....", error)
      });
  };



  // const totalTokensPurchased = invesmentsAry?.reduce((accumulator, currentItem) => {
  //   return accumulator + parseFloat(currentItem?.tokensPurchashed);
  // }, 0);

  // // Calculate the total sum of investments
  // const totalInvestment = invesmentsAry?.reduce((accumulator, currentItem) => {
  //   return accumulator + parseFloat(currentItem?.investment);
  // }, 0);



  // let sumTokensPurchased = 0;
  // let sumInvestment = 0;

  // // Loop through the array and sum the values
  // for (const investment of invesmentsAry) {
  //   sumTokensPurchased += parseFloat(investment.tokensPurchased);
  //   sumInvestment += parseFloat(investment.investment);
  // }

  // console.log("Sum of tokensPurchased:", sumTokensPurchased);
  // console.log("Sum of investment:", sumInvestment);


  // const claimsHandle = async () => {
  //   console.log('start graph......');

  //   var data = JSON.stringify({
  //     query: `query MyQuery {
  //           claimeds(where: {by: "${account}"}) {
  //             by
  //             tokensClaimed
  //             blockTimestamp
  //           }
  //         }`,
  //   });
  //   var config = {
  //     method: "post",
  //     url: 'https://api.thegraph.com/subgraphs/name/saifdevblochain/goldenloonie',
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   await axios(config)
  //     .then(async function (response) {
  //       console.log("🚀 ~ file: claimsAry ~ response:", response)
  //       setclaimsAry(response.data.data.claimeds);
  //     })
  //     .catch(function (error) {
  //       console.log("🚀 ~ file: Investors.js:88 ~ error: Investors....", error)
  //     });
  // };



  useEffect(() => {
    if (account) {
      invesmentsHandle();
      // fund();
    }
  }, [account]);




  let asd = glnBlance / glnTokenPrice;
  console.log(asd, 'asdpppp');
  return (
    <div>
      <section className='main-dashbord'>
        <div className='mainheading'>
          <h2>Welcome, {userData?.name}</h2>
        </div>
        <div className='dashbordcard'>
          <div className='innercard w-100'>
            <div className='maincardparent'>
              <div className='right'>
                <h6>Wallet Balance</h6>
              </div>
              <div className='left'>
                <h4>{(glnBlance)?.toFixed(4)} GLN</h4>
                <p>{(glnBlance / glnTokenPrice)?.toFixed(4)} USD</p>
              </div>
            </div>
          </div>
          {/* <div className='innercard'>
            <div className='maincardparent'>
              <div className='right'>
                <h6>Buy GLN</h6>
              </div>
              <div className='left'>
                <NavHashLink to={'/#with-hash-fragment'}>
                  <button>Buy token</button>
                </NavHashLink>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className='investment'>
          <h2>Investments</h2>
          <table class="table table-striped my-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Wallet Address</th>
                <th scope="col">Amount</th>
                <th scope="col">Time</th>
              </tr>
            </thead>
            <tbody>
              {
                invesmentsAry?.length > 0 ?
                  <>
                    {
                      invesmentsAry?.map((tblData, index) => {
                        // console.log(tblData.investment, 'tblData.investment  invesmentss', tblData);
                        const timestamp = tblData.blockTimestamp; // Replace with the actual timestamp property name
                        const date = new Date(timestamp * 1000);
                        const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{tblData.by}</td>
                            <td>{tblData.investment / 10 ** 18}</td>
                            <td>{formattedDate}</td>
                          </tr>
                        )
                      })
                    }
                  </>
                  :
                  <tr>
                    <td colSpan={'4'} className='text-center'>No recoud found</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
        <div className='investment'>
          <h2>Rewards</h2>
          <table class="table table-striped my-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Wallet Address</th>
                <th scope="col">Amount</th>
                <th scope="col">Time</th>
              </tr>
            </thead>
            <tbody>
              {
                claimsAry?.length > 0 ?
                  claimsAry?.map((tblData, index) => {
                    // console.log(tblData.investment, 'tblData.investment  invesmentss', tblData);
                    const timestamp = tblData.blockTimestamp; // Replace with the actual timestamp property name
                    const date = new Date(timestamp * 1000);
                    const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{tblData.by}</td>
                        <td>{tblData.investment / 10 ** 18}</td>
                        <td>{formattedDate}</td>
                      </tr>
                    )
                  })
                  :
                  <tr>
                    <td colSpan={'4'} className='text-center'>No recoud found</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>



        <div className='row'>
          <div className='col-12 p-0'>
           
            <ClaimComp hashId={'with-hash-fragment'} />
          </div>
        </div> */}
        <div className='headings'>
          <h2>My Investments</h2>
        </div>
        <div className='table-responsive'>
          <table className='table'>
            <thead className='tblheadss'>
              <tr>
                <th>Amount</th>
                <th>Share in fund’s</th>

              </tr>
            </thead>
            <tbody>
              {
                invesmentsAry?.length > 0 ?
                  // invesmentsAry?.map((tblData, index) => {
                  //   let num = tblData.tokensPurchashed / 10 ** 18
                  //   let a = getFundEndlimit
                  //   console.log(tblData, 'tblData.investment  invesmentss', num, a);
                  //   return (
                  //     <tr key={index}>
                  //       <td>{web3.utils.fromWei(tblData.investment, "mwei")} USDT</td>
                  //       <td>{((num / getFundEndlimit) * 100)?.toFixed(4)} %</td>
                  //     </tr>
                  //   )
                  // })
                  <tr>
                    {/* <td>{web3.utils.fromWei(tblData.investment, "mwei")} USDT</td> */}
                    <td>{stateOne} USDT</td>
                    <td>{((stateSecond / parseInt(getFundEndlimit)) * 100)?.toFixed(4)} %</td>
                  </tr>
                  :
                  <tr>
                    <td colSpan={'4'} className='text-center'>No recoud found</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </section>
    </div>
  )
}

export default Dashbord