import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { preSaleTokenContractHelper } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const ApproveST = () => {
    let { account } = useWeb3React()
    const web3 = useWeb3();
    const contractAddress = environment.preSaleToken;
    const preSaleContractAddress = environment.preSaleContract;
    const contract = preSaleTokenContractHelper(contractAddress, web3);

    const userApprove = useCallback(
        async (aproveAmount) => {
            var gasFunPrice;
            web3.eth.getGasPrice().then((result) => {
                var result2 = parseInt(result) + 3000000000
                // // console.log("gasfun", typeof result2, result2)
                gasFunPrice = result2.toString()
            })

            // let amount = '9999999999999999999999999999';
            console.log(aproveAmount, 'aproveAmount in contract fileee');
            let stTAmount = web3.utils.toWei(aproveAmount.toString(), "ether");
            console.log(stTAmount, 'stTAmount........');

            try {
                const gas = await contract.methods
                    .approve(preSaleContractAddress, stTAmount)
                    .estimateGas({
                        from: account,
                    })
                const details = await contract.methods
                    .approve(preSaleContractAddress, stTAmount)
                    .send({ gas: gas, gasPrice: gasFunPrice, from: account })
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { userApprove: userApprove };
};
export default ApproveST;