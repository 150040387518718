import "./navbar.scss";
import { Link, useHistory } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useWeb3React } from "@web3-react/core";
import useAuth from '../../../hooks/useAuth';
import axios from "axios";
import { API_URL } from "../../../utils/ApiUrl";
import { useEffect } from "react";
import placeholderImg from '../../../Assests/placeholderImg.png';
import { toast } from "react-toastify";

const Navbar = () => {

  const history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);


  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  const { login, logout } = useAuth();
  const { account } = useWeb3React();


  let localUserId = localStorage.getItem('userId');
  let payload = { walletAddress: account }

  const [responseData, setResponseData] = useState(null);
  console.log("Navbar ~ responseData::::", responseData)

  // const createUserHandle = () => {
  //   axios.post(`${API_URL}/v1/users/create`, payload)
  //     .then(response => {
  //       console.log(response.data, 'create user..........');
  //       setResponseData(response.data);
  //       if (response.data) {
  //         localStorage.setItem('userId', response?.data?.user?.id)
  //       }
  //       if (response.data.msg === "Wallet address must be unique.") {
  //         toast.error(response.data.msg);
  //       }
  //       else {
  //         toast.success(response.data.msg);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:::::::::::::::::::>>>>>>>>>>>>>>>>', error);
  //       toast.error(error);
  //     });
  // };




  const connectMetamask = async (e) => {
    console.log(e, 'eeeeeeeee');
    handleClose6();
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
      // setAuthStatus('')
    } else {
      login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      handleShow2();
      // toast?.success("Wallet connected")
      // setAuthStatus('signUp')
    }
  };

  const trustWallet = async () => {
    console.log('startt trust.....');
    handleClose6();
    window.$('#exampleModalwallet').modal('hide')
    // handleShow()
    if (account) {
      await logout("walletconnect");
      // setAuthStatus('')
    } else {
      let a = await login("walletconnect");
      // setAuthStatus('signUp')
      console.log(a, 'aaaaa');
      localStorage.setItem('connectorId', 'walletconnect');
      localStorage.setItem("flag", "true");
      // localStorage.setItem("chain", "");
    }
  };


  const logoutHandle = async () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId)
    localStorage.removeItem('connectorId')
    localStorage.removeItem('flag')
    localStorage.removeItem('chain')
    // window.location.reload()
    history?.push("/");
  }

  const userLogOutHandle = () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('accessToken')
    // window.location.reload();
    history?.push("/")
  }

  // useEffect(() => {
  //   if (account && !localUserId) {
  //     createUserHandle();
  //   } else {
  //     // getUserHandle();
  //   }
  // }, [account])



  const [userData, setUserData] = useState(null);
  console.log("🚀 ~ file: Landing.js:23 ~ Landing ~ userData:", userData)

  // const { account } = useWeb3React();

  // let localUserId = localStorage.getItem('userId');


  const getUserHandle = async () => {
    try {
      const response = await axios.post(`${API_URL}/v1/users/getUser`, { _id: localUserId });

      if (response) {
        // Handle success
        setUserData(response.data);
        console.log('Request successful', response.data);
      } else {
        // Handle error
        console.error('Request failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    if (localUserId) {
      // createUserHandle();
      getUserHandle();
    } else {
    }
  }, [account])

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <>
      <section className={isScrolled ? "main-navbar isscrolled" : "main-navbar"}>
        <div className="container-fluid padd-sm">
          <nav class="navbar navbar-expand-lg">
            <Link to="/" class="navbar-brand"><img src="\assets\logo.svg" alt="img" className="img-fluid" /></Link>
            <div className="twice-btn">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class=""><img src="\assets\menu.svg" alt="img" className="img-fluid" /></span>
              </button>
            </div>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <HashLink to="#" class="nav-link" aria-current="page"
                    style={
                      isActive("")
                        ? {
                          color: "#E1A800",
                          fontWeight: "600"
                        }
                        : {}
                    }
                  >About us</HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="#work" class="nav-link"
                    style={
                      isActive("#work")
                        ? {
                          color: "#E1A800",
                          fontWeight: "400"
                        }
                        : {}
                    }
                  >How it works</HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="#Faqs" class="nav-link"
                    style={
                      isActive("#about")
                        ? {
                          color: "#E1A800",
                          fontWeight: "400"
                        }
                        : {}
                    }
                  >FAQs</HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="#Lastsection" class="nav-link"
                    style={
                      isActive("#about")
                        ? {
                          color: "#E1A800",
                          fontWeight: "400"
                        }
                        : {}
                    }
                  >Contact Us</HashLink>
                </li>
                <li class="nav-item">
                  <a href="https://www.gitbook.com/" target="_blank" class="nav-link"
                    style={
                      isActive("#faqs")
                        ? {
                          color: "#E1A800",
                          fontWeight: "600",

                        }
                        : {}
                    }
                  >Docs</a>
                </li>
                <li class="nav-item">
                  <a href="#" target="_blank" class="nav-link"
                    style={
                      isActive("#projects")
                        ? {
                          color: "#E1A800",
                          fontWeight: "600",

                        }
                        : {}
                    }
                  >Projects & Investments</a>
                </li>
                <li class="nav-item">
                  <a href="#" target="_blank" class="nav-link"
                    style={
                      isActive("#news")
                        ? {
                          color: "#E1A800",
                          fontWeight: "600",

                        }
                        : {}
                    }
                  >News</a>
                </li>
                <li class="nav-item">
                  <a href="#" target="_blank" class="nav-link"
                    style={
                      isActive("#community")
                        ? {
                          color: "#E1A800",
                          fontWeight: "600",

                        }
                        : {}
                    }
                  >Community</a>
                </li>
                <li class="nav-item">
                  <a href="#" target="_blank" class="nav-link"
                    style={
                      isActive("#jobs")
                        ? {
                          color: "#E1A800",
                          fontWeight: "600",

                        }
                        : {}
                    }
                  >Jobs</a>
                </li>
              </ul>
              <div className="right-side">
                {
                  localUserId ?

                    <div className="dropbtn">

                      {
                        account ?
                          // <button className="buttonendnav" onClick={handleShow6}  >Connect Wallet</button>
                          <button className="login1" onClick={logoutHandle}>
                            <img src="\assets\logout.svg" alt="img" className="img-fluid" />
                            Disconnect
                          </button>
                          :
                          <button className="login1" onClick={handleShow6}>
                            <img src="\assets\login\empty-wallet.svg" alt="img" className="img-fluid" />
                            Connect Wallet
                          </button>
                      }

                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="toggleinner">
                            <div className="mainimagesss">
                              <img src={userData?.picture ? userData?.picture : placeholderImg} alt="img" className="inneravatar" />
                            </div>
                            <p>{userData?.name ? userData.name : "Anonymous user"}</p>
                            <img src='\assets\arrowdowntoggle.svg' alt='img' className="ciyvyydvcy" />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {
                            account ?
                              <Link to="/Dashboardpanel">
                                <li className="dropdown-item">
                                  <img src="\assets\category.svg" alt="img" className="img-fluid" />
                                  Dashboard
                                </li>
                              </Link>
                              : null
                          }
                          {
                            localUserId ?
                              <Link to="/setting">
                                <li className="dropdown-item">
                                  <img src="\assets\setting-2.svg" alt="img" className="img-fluid" />
                                  Settings
                                </li>
                              </Link> : null
                          }
                          {

                          }
                          <li style={{ cursor: 'pointer' }} className="dropdown-item" onClick={userLogOutHandle}>
                            <img src="\assets\logout.svg" alt="img" className="img-fluid" />
                            Log Out
                          </li>
                          {/* {
                            account ?
                              :
                              <li className="dropdown-item" onClick={handleShow6}>
                                <img src="\assets\login\empty-wallet.svg" alt="img" className="img-fluid" />
                                Connect Wallet
                              </li>
                          } */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    :
                    <div className='navbtns'>
                      <Link to={'/signup'}>
                        <button className="login1">Sign Up</button>
                      </Link>
                      <Link to={'/login'}>
                        <button className="login1">Log in</button>
                      </Link>
                      {/* <Link className="buttonendnav" onClick={handleShow6}  >Connect Wallet</Link> */}
                    </div>
                }

              </div>
            </div>
          </nav>
        </div>
      </section>

      <Modal className='profilemodal' show={show6} onHide={handleClose6} centered>
        <Modal.Header className='modalhndg' closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body className='conectmodalbody'>
          <div className="button-modal1 dflex">
            <button className="modal-buttons" onClick={() => connectMetamask('80001')}>
              <img
                src="\assets\metamask-icon.svg"
                className="img-fluid"
              />
              <h3 className=""> MetaMask</h3>
              {/* <p className="">Connect to your MetaMask wallet </p> */}
            </button>
            <button className="modal-buttons" onClick={() => trustWallet()}>
              <img
                src="\assets\walletconnect.svg"
                className="img-fluid"
              />
              <h3 className="">WalletConnect</h3>
              {/* <p className="">Connect to your MetaMask wallet </p> */}
            </button>
          </div>
        </Modal.Body>

      </Modal>
      {/* <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        </Offcanvas.Body>
      </Offcanvas> */}


      <Modal className='profilemodal' show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Wallet connect</Modal.Title>
        </Modal.Header>
        <Modal.Body className='invest_modalbody'>
          <div className='parenttick'>
            <div className='images'>
              <img src='\assets\successtick.svg' alt='img' className='img-fluid' />
            </div>
            <div className='text'>
              <p>Wallet connected successfully</p>
            </div>
            <div className='okaybtn' onClick={handleClose2}>
              <button>Okay</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
