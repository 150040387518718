import React from 'react'
import "./Passwordsuccess.scss"
import { Link } from 'react-router-dom'
const Passwordsuccess = () => {
    return (
        <>
            <section className='success'>
                <div className="custom-container">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='backbutton'>
                                <Link to="/"><img src='\assets\arrow-left.svg' alt='img' className='img-fluid' />Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-7 m-auto'>
                            <div className='parentpic'>
                                <div className='maincard'>
                                    <div className='passwordimage'>
                                        <img src='\assets\passwordpic.svg' alt='img' className='innerpassword' />
                                    </div>
                                    <div className='heading'>
                                        <h2>Success!</h2>
                                        <p>Your Password has been successfuly changed</p>
                                        <Link to={'/login'}>
                                            <button>Log In</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Passwordsuccess